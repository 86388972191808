import * as IFace from './latLong.interface.reducer'
import * as TYPE from '../type-redux/index'

const latLang: IFace.LatLong = {
    latitude: null,
    longitude: null
}

export const LatLong = (state = latLang, action: any): any => {
    switch (action.type) {
        case TYPE.LAT_LANG:
            return {
                ...state,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude
            }
            break;
        default:
            break;
    }
    return state;
}