import React, { ReactElement } from 'react'
import QRCode from 'qrcode.react'
import { getDataPaymentDetail, PAYMENT_GET_DATA_DETAIL } from './../../actions/index'
import paymentStore from './../../stores/payment.store'
import { notify } from 'react-notify-toast';
import prettyNumber from './../../utils/pretty-number/index'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import { useParams } from "react-router"
import { Clipboard, TouchableOpacity, View } from 'react-native'
import Seo from '../../components/seo/index'
import Loaded from '../../components/loaded/index'

export const PaymentDetail = (): ReactElement => {
    const { code }: { code: string } = useParams()
    const [paymentCode, setPaymentCode] = React.useState<string>()
    const [isCode, setIsCode] = React.useState<string>(code)
    const [name, setName] = React.useState<string>()
    const [msg, setMsg] = React.useState<string>()
    const [amount, setAmount] = React.useState<number>()
    const [maxTime, setMaxTime] = React.useState<number>()
    const [value, setValue] = React.useState<string>()

    const _onPaymentDetailGetData = (status: boolean) => {
        let result = paymentStore.isGetPaymentDetail()
        if (status && result) {
            setPaymentCode(result.payment_info_str)
            setIsCode(result.internal_payment_code)
            setName(result.name)
            setMsg(result.msg)
            setAmount(result.amount_origin)
            setMaxTime(result.max_time)
            setValue(result.value)
        }
        else {
            notify.show("Sistem sibuk", "error", 3000)
        }
    }

    React.useEffect(() => {
        getDataPaymentDetail(isCode)
        paymentStore._addListener(PAYMENT_GET_DATA_DETAIL, _onPaymentDetailGetData)

        return () => {
            paymentStore._removeListener(PAYMENT_GET_DATA_DETAIL, _onPaymentDetailGetData)
        }
    }, [])

    const _copyValue = (data: string) => {
        Clipboard.setString(data)
        notify.show("Berhasil dicopy", "success", 3000)
    }

    return (
        <React.Fragment>
            <Seo
                title={`DETAIL PEMBAYARAN - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <div className="card card-style">
                <div className="text-center pt-4 pb-4">
                    <h1>{paymentCode ? paymentCode == "QRIS" ? "QRIS Pembayaran Kamu" : (paymentCode == "CPVA" ? "VA Pembayaran Kamu" : "...") : "Loading..."}</h1>
                    <p className="boxed-text-xl">
                        Hello {name ?? "Annonym"}, gunakan kode ini untuk membayar <strong>{msg ?? "..."}</strong> senilai {prettyNumber(amount, false, 0, 0, "...")} IDR sebelum {maxTime ? moment.unix(maxTime).format("DD/MM/YYYY HH:MM:ss") : "..."}
                    </p>
                    {/* <img width="250" className="generate-qr-auto mx-auto polaroid-effect shadow-l rounded-m" src="" /> */}
                    {
                        paymentCode && paymentCode == "QRIS" ? (
                            <React.Fragment>
                                <QRCode size={250} value={String(value)} />
                                <p className="text-center font-10 pt-2 mb-0">Scanning this QR by your ewallet scanner.</p>
                            </React.Fragment>
                        ) : paymentCode == "CPVA" ? (
                            <React.Fragment>
                                <TouchableOpacity onPress={() => { _copyValue(value ?? "") }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#fafafa', marginHorizontal: 32, padding: 8 }}>
                                        <h2>Virtual Account</h2>
                                        <h2>:</h2>
                                        <h2>{value}</h2>
                                    </View>
                                </TouchableOpacity>
                                <p className="text-center font-10 pt-2 mb-0">Klik untuk menyalin code.</p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                LOADING
                            </React.Fragment>
                        )
                    }
                </div>
            </div>

            <div className="card card-style">
                <div className="content mb-0">
                    <h2>Petunjuk pembayaran</h2>
                    <p className="mb-2">Baca panduan pembayaran menggunakan QRIS dibawah ini ya</p>
                    {
                        paymentCode && paymentCode == "QRIS" ? (
                            <React.Fragment>
                                <ul>
                                    <li>Scan code dengan ewallet (Gopay, ShoppePay, Dana, LinkAja, Mandiri QRIS dll), atau</li>
                                    <li>Screen shoot code diatas, terus upload pada ewallet scan</li>
                                    <li>Jika sudah selesai, silahkan klik tombol dibawah</li>
                                </ul>
                            </React.Fragment>
                        ) : paymentCode == "CPVA" ? (
                            <React.Fragment>
                                <ul>
                                    <li>Login ke akun bank Anda</li>
                                    <li>Pilih transfer Antar Bank/ ATM Bersama</li>
                                    <li>Pilih Bank Permata</li>
                                    <li>Masukkan kode virtual dan nominal</li>
                                    <li>Masukkan sandi</li>
                                    <li>Selesai</li>
                                </ul>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                loading data...
                            </React.Fragment>
                        )
                    }
                    <a href={`/payment/status/${isCode}`} className="generate-qr-button btn btn-l btn-full shadow-l rounded-s bg-highlight font-600 mt-4">Sudah Dibayar ?</a>
                </div>
                <div className="divider divider-margins mb-2 mt-4"></div>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
        </React.Fragment>
    )
}
