import * as IFace from './auth.interface.reducer'
import * as TYPE from './../type-redux/index'

const auth: IFace.Auth = {
    login: null
}

export const Auth = (state = auth, action: any): any => {
    switch (action.type) {
        case TYPE.AUTH_LOGIN:
            return {
                ...state,
                login: action.payload
            }
            break;
        default:
            break;
    }
    return state;
}