import jwt_decode from "jwt-decode";
import { readLocalStorage } from './../local-storage/index'

export const checkAuth = async (name: string): Promise<any> => {
    let tokenAuth = await readLocalStorage(name)
    if (tokenAuth) {
        try {
            let result = await jwt_decode(tokenAuth);
            return result
        }
        catch (e) {
            return null
        }
    }
    return null
}