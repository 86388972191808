import React, { ReactChild } from 'react';
import ContentLoader from "react-content-loader"

export const SliderLoader = (props: any) => (

    <div className="card card-style card-responsive-parent card-margin">
        <ContentLoader
            speed={2.5}
            style={{ width: '100%' }}
            viewBox="0 0 480 234"
            backgroundColor="#fff"
            foregroundColor="#E5E5E5"
            {...props}
        >
            <rect x="0" y="0" rx="16" ry="16" width="100%" height="100%" />
        </ContentLoader>
    </div>
)