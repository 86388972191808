export default function (nominal: any, short: boolean = false, minVal: number = 10000, fixed: any = 0, noData?: string): string | null {
    if (!nominal) { return noData ?? "-" }
    if (nominal < minVal || !short) { return nominal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
    if (nominal === null) { return null } // terminate early
    if (nominal === 0) { return '0' } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed // number of decimal places to show
    var b = (nominal).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? nominal.toFixed(0 + fixed) : (nominal / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', ' Rb', ' Jt', ' Ml', 'Tn'][k] // append power
    return e
}
