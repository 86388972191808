import dispatcher from '../utils/flux/index'
import { USER_GET_DATA, USER_UPDATE_DATA } from './index'
import * as IUser from './../stores/user.interface.store'
export const getDataUser = (): void => {
    dispatcher.dispatch({
        type: USER_GET_DATA
    })
}

export const updateDataUser = (data: any): void => {
    dispatcher.dispatch({
        type: USER_UPDATE_DATA,
        payload: data
    })
}