import React, { ReactElement } from 'react';
import { notify } from 'react-notify-toast';
import { useParams } from "react-router"
import {
    AUTH_ACTIVATION,
    authActivation
} from './../../actions/index'
import authStore from './../../stores/auth.store'
import { Link, Redirect } from 'react-router-dom'
import Loaded from '../../components/loaded/index'

export const Activation = (): ReactElement => {

    const { token }: { token: string } = useParams()
    const [redirect, setRedirect] = React.useState<string>();

    const _onActivation = () => {
        let result = authStore.isDataActivation()
        let msg: string = "sistem sibuk";
        let type: "error" | "success" = "error";
        if (result) {
            if (result.status) {
                msg = result.message;
                type = "success";
            }
            else {
                msg = result.message;
                type = "error";
            }
        }
        notify.show(msg, type, 5000)
        setRedirect("/login")
    }

    React.useEffect(() => {
        // authActivation(token)
        authStore._addListener(AUTH_ACTIVATION, _onActivation)

        authActivation(token);

        return () => {
            authStore._removeListener(AUTH_ACTIVATION, _onActivation)
        }

    }, [])
    return (
        <React.Fragment>
            &nbsp;
            {/* jangan di hapus */}
            <Loaded data={null} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    );
}
