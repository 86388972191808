import React, { ReactElement } from 'react';
import {
    Link
} from "react-router-dom";
import { useLocation } from 'react-router-dom';

export const Footer = (): ReactElement => {
    const location = useLocation();
    const [linkActive, setLinkActive] = React.useState<string>()
    React.useEffect(() => {
        setLinkActive(location.pathname)
    })
    return (
        <>
            <div id="footer-bar" className="footer-bar-6" style={{ maxWidth: 480, margin: 'auto' }}>
                <Link to={`/worship-place`} style={{ color: linkActive === '/worship-place' ? '#185D41' : '' }}>
                    <i className="fa fa-mosque"></i>
                    <span>T. Ibadah</span>
                </Link>
                <Link to={`/program`} style={{ color: linkActive === '/program' ? '#185D41' : '' }}>
                    <i className="fa fa-clipboard-list"></i>
                    <span>Program</span>
                </Link>
                <Link to={`/`} style={{ color: linkActive === '/' ? '#185D41' : '' }}>
                    <i className="fa fa-home"></i>
                    <span>Beranda</span>
                </Link>
                <Link to={`/institution`} style={{ color: linkActive === '/institution' ? '#185D41' : '' }}>
                    <i className="fa fa-building"></i>
                    <span>Lembaga</span>
                </Link>
                <Link to={`/account`} style={{ color: linkActive === '/account' ? '#185D41' : '' }}>
                    <i className="fa fa-user"></i>
                    <span>Akun</span>
                </Link>
            </div>
        </>
    );
}

