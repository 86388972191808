import React, { ReactElement } from 'react'
import * as IFacePayment from './../../stores/payment.interface.store'
import SimpleReactValidator from 'simple-react-validator'
import prettyNumber from './../../utils/pretty-number/index'
import { FlatList, TouchableOpacity, View } from 'react-native'

interface IInfaqDataContent {
    type: 'worship-place' | 'program' | 'institution',
    contentUniqueCode?: string,
    refCode?: string,
}

interface IInfaqContent {
    headerTitle?: string,
    data: IFacePayment.ModelPayments[] | undefined,
    dataContent?: IInfaqDataContent,
    withDataUser: boolean,
    disableBtn: boolean,
    actionCallBack: (data: any) => void
}
export default ({ actionCallBack, dataContent, withDataUser, data, headerTitle, disableBtn }: IInfaqContent): ReactElement => {
    const [page, setPage] = React.useState<string>("form-infaq")
    const [amount, setAmount] = React.useState<string>("")
    const [alias, setAlias] = React.useState<string>("")
    const [email, setEmail] = React.useState<string>("")
    const [phone, setPhone] = React.useState<string>("")
    const [selected, setSelected] = React.useState<number>()
    const [isAnnonym, setIsAnnonym] = React.useState<boolean>(false)
    const [otherNumber, setOtherNewNumber] = React.useState<number>()
    const [otherNumberCheck, setOtherNewNumberCheck] = React.useState<boolean>(false)
    const [isSelect, setIsSelect] = React.useState<number>()

    const [, forceUpdate] = React.useState<number>();
    const simpleValidator = React.useRef(new SimpleReactValidator())

    const _parsePrettytoNumber = (value: any) => {
        return String(value).split("Rp.").join("")
            .split(" ").join("")
            .split(",").join("")
    }

    let amountValidator = simpleValidator.current.message('amount', _parsePrettytoNumber(amount), 'required|numeric|min:1,num');
    let nameValidator = simpleValidator.current.message('name', alias, 'required|string');
    let emailValidator = simpleValidator.current.message('email', email, 'required|email');
    let phoneValidator = simpleValidator.current.message('phone', phone, 'required|numeric');

    const _setAmountWithPretty = (value: string): void => {
        let amount = "Rp. " + prettyNumber(_parsePrettytoNumber(value), false, 0, 0, "")
        setIsSelect(parseInt(_parsePrettytoNumber(value)))
        setAmount(amount)
    }

    const _onButtonPress = (): void => {
        forceUpdate(0)
        if (withDataUser && simpleValidator.current.allValid() && amount) {
            _action("go-payment-methode")
        } else if (!otherNumberCheck && !withDataUser && simpleValidator.current.fieldValid('amount') && amount) {
            _action("go-payment-methode")
        }
        else if (otherNumberCheck && !withDataUser && simpleValidator.current.fieldValid('phone') && simpleValidator.current.fieldValid('amount') && amount) {
            _action("go-payment-methode")
        }
        else {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    };

    const _goInfaq = (): void => {
        let data: any;
        data = {
            //cashlez data
            amount: parseInt(_parsePrettytoNumber(amount)),
            message: '',
            paymentMessage: '',
            description: '',
            //+insert db
            customerMobilePhone: otherNumberCheck ? otherNumber : phone,
            customerLatitude: '',
            customerLongitude: '',
            //for check to db
            paymentMethod: selected,
            //insert to db
            email: email,
            alias: alias,
            isAnnonym: isAnnonym,
            //for internal
            contentCode: dataContent?.contentUniqueCode,
            contentType: dataContent?.type,
            trafficRef: dataContent?.refCode,
        }
        console.log(data)
        actionCallBack(data)
    }

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "go-payment-methode":
                setPage("payment-methode")
                break
            case "go-form-infaq":
                setPage("form-infaq")
                break
            case "selected":
                setSelected(data.id)
                break
            case "go-infaq":
                _goInfaq()
                break
        }
    }
    if (page == "form-infaq") {
        return (
            <React.Fragment>
                <h2 className="mt-2">{headerTitle}</h2>
                <div className="input-style input-style-2 input-required mt-2">
                    <div className="mb-2">
                        <button onClick={() => _setAmountWithPretty('5000')} style={isSelect == 5000 ? { color: '#fff' } : { color: '#727272' }} className={isSelect == 5000 ? "btn btn-success btn-sm" : "btn btn-outline-success btn-sm"}>Rp.5.000</button>
                        <button onClick={() => _setAmountWithPretty('10000')} style={isSelect == 10000 ? { color: '#fff' } : { color: '#727272' }} className={isSelect == 10000 ? "btn btn-success btn-sm ml-1" : "btn btn-outline-success btn-sm ml-1"}>Rp.10.000</button>
                        <button onClick={() => _setAmountWithPretty('20000')} style={isSelect == 20000 ? { color: '#fff' } : { color: '#727272' }} className={isSelect == 20000 ? "btn btn-success btn-sm ml-1" : "btn btn-outline-success btn-sm ml-1"}>Rp.20.000</button>
                        <button onClick={() => _setAmountWithPretty('50000')} style={isSelect == 50000 ? { color: '#fff' } : { color: '#727272' }} className={isSelect == 50000 ? "btn btn-success btn-sm mt-2" : "btn btn-outline-success btn-sm mt-2"}>Rp.50.000</button>
                        <button onClick={() => _setAmountWithPretty('70000')} style={isSelect == 70000 ? { color: '#fff' } : { color: '#727272' }} className={isSelect == 70000 ? "btn btn-success btn-sm ml-1 mt-2" : "btn btn-outline-success btn-sm ml-1 mt-2"}>Rp.70.000</button>
                        <button onClick={() => _setAmountWithPretty('100000')} style={isSelect == 100000 ? { color: '#fff' } : { color: '#727272' }} className={isSelect == 100000 ? "btn btn-success btn-sm ml-1 mt-2" : "btn btn-outline-success btn-sm ml-1 mt-2"}>Rp.100.000</button>
                    </div>
                    <input name="amount" value={amount} onChange={(e) => _setAmountWithPretty(e.target.value)} placeholder="Rp.20.000" className="form-control" type="name" autoFocus={true} />
                    <p style={{ color: 'red' }} className={amountValidator ? "mb-n2" : "mb-1"}>{amountValidator}</p>
                </div>
                {
                    withDataUser && (
                        <React.Fragment>
                            <div className="input-style input-style-2 input-required mt-2">
                                <input name="alias" value={alias} onChange={(e) => setAlias(e.target.value)} placeholder="nama kamu" className="form-control" type="name" />
                                <p style={{ color: 'red' }} className={nameValidator ? "mb-n2" : "mb-1"}>{nameValidator}</p>
                            </div>
                            <div className="input-style input-style-2 input-required mt-2">
                                <input name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email kamu" className="form-control" type="name" />
                                <p style={{ color: 'red' }} className={emailValidator ? "mb-n2" : "mb-1"}>{emailValidator}</p>
                            </div>
                            <div className="input-style input-style-2 input-required mt-2">
                                <input name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="no hp (wajib 08XX)" className="form-control" type="name" />
                                <p style={{ color: 'red' }} className={phoneValidator ? "mb-n2" : "mb-1"}>{phoneValidator}</p>
                            </div>
                        </React.Fragment>
                    )
                }
                {
                    otherNumberCheck && (
                        <React.Fragment>
                            <div className="input-style input-style-2 input-required mt-2">
                                <input name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="no hp (wajib 08XX)" className="form-control" type="name" />
                                <p style={{ color: 'red' }} className={phoneValidator ? "mb-n2" : "mb-1"}>{phoneValidator}</p>
                            </div>
                        </React.Fragment>
                    )
                }
                <div style={{ marginLeft: 20, marginTop: -8, marginBottom: 8 }}>
                    <input checked={isAnnonym} id="annonym" name="annonym" onChange={(e) => { setIsAnnonym(!isAnnonym) }} className="form-check-input" type="checkbox" value="1" />
                    <label htmlFor="annonym">Donasi sebagai annonym</label>
                </div>
                {
                    !withDataUser && (
                        <div style={{ marginLeft: 20, marginTop: -8, marginBottom: 8 }}>
                            <input checked={otherNumberCheck} id="otherNumber" name="otherNumber" onChange={(e) => { setOtherNewNumberCheck(!otherNumberCheck) }} className="form-check-input" type="checkbox" value="1" />
                            <label htmlFor="otherNumber">Dengan nomor hp berbeda?</label>
                        </div>
                    )
                }
                <View>
                    <TouchableOpacity onPress={() => { _onButtonPress() }}>
                        <button className="btn btn-full gradient-blue font-13 btn-l font-600 mb-3 mt-1 rounded-sm" style={{ width: '100%' }}>
                            PILIH METODE PEMBAYARAN
                        </button>
                    </TouchableOpacity>
                </View>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                <FlatList
                    data={data}
                    ListHeaderComponent={() => (
                        <React.Fragment>
                            <TouchableOpacity onPress={() => { _action("go-form-infaq") }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ width: 50, height: 50, marginRight: 12, marginLeft: 4 }}>
                                        <i className="fa fa-angle-left font-17" style={{ margin: 'auto' }}></i>
                                    </View>
                                    <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                                        <span className="font-600 font-17 mb-n1 color-highlight mt-1">Kembali</span>
                                        <span>Kembali ke form sebelumnya?</span>
                                    </View>
                                </View>
                            </TouchableOpacity>
                            <div className="divider mt-1 mb-1"></div>
                        </React.Fragment>
                    )}
                    renderItem={({ item }) => {
                        let isAmount = parseInt(_parsePrettytoNumber(amount))
                        if (isAmount >= item.min_amount) {
                            return (
                                <React.Fragment key={item.code}>
                                    <TouchableOpacity onPress={() => { _action("selected", { id: item.id }) }}>
                                        <View style={{ flexDirection: 'row', backgroundColor: item.id == selected ? "#BEF5C9" : "", alignItems: 'center', borderRadius: 4 }}>
                                            <View style={{ width: 50, height: 50, marginRight: 12, marginLeft: 4 }}>
                                                {item.logo && (<img src={item.logo} style={{ width: 50, height: 50, borderRadius: 4 }} />)}
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <span className="font-600 mb-n1 color-highlight mt-1">{item.name}</span>
                                                <span>{item.desc}</span>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                    <div className="divider mt-1 mb-1"></div>
                                </React.Fragment>
                            )
                        }
                        else {
                            return <React.Fragment></React.Fragment>
                        }
                    }}
                    keyExtractor={(item) => item.code}
                    ListFooterComponent={() => (
                        <React.Fragment>
                            {
                                !selected && (
                                    <p className="mb-0 color-highlight">Pilih salah satu metode pembayaran diatas</p>
                                )
                            }
                            <TouchableOpacity onPress={() => _action("go-infaq")}>
                                <button disabled={disableBtn ? true : (selected ? false : true)} className="btn btn-full gradient-blue font-13 btn-l font-600 mb-3 mt-3 rounded-sm" style={{ width: '100%' }}>
                                    {disableBtn ? "LOADING..." : "INFAK SEKARANG"}
                                </button>
                            </TouchableOpacity>
                        </React.Fragment>
                    )}
                />
            </React.Fragment>
        )
    }
}
