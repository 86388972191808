export const setCookie = async (name: string, value: string, exdays: number): Promise<boolean> => {
    try {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
        return true;
    }
    catch (e) {
        return false;
    }
}

export const readCookie = (name: string): string => {
    let isname: string = name + "=";
    let ca: string[] = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(isname) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}