import React, { ReactChild } from 'react';
import ContentLoader from "react-content-loader"

export const List1Loader = (props: any) => (
    <div className="card card-style card-responsive-parent card-auto card-margin">
        <div className="content">
            <ContentLoader
                speed={2}
                style={{ width: '100%' }}
                viewBox="0 0 480 230"
                backgroundColor="#fff"
                foregroundColor="#E5E5E5"
                {...props}
            >
                <rect x="0" y="0" rx="4" ry="4" width="310" height="25" />

                <rect x="0" y="40" rx="4" ry="4" width="190" height="15" />
                <rect x="220" y="40" rx="4" ry="4" width="125" height="15" />

                <rect x="0" y="70" rx="4" ry="4" width="100" height="30" />
                <rect x="260" y="70" rx="4" ry="4" width="84" height="30" />

                <rect x="380" y="0" rx="4" ry="4" width="100" height="100" />

                <rect x="0" y="115" rx="4" ry="4" width="100%" height="1" />

                <rect x="0" y="130" rx="4" ry="4" width="310" height="25" />

                <rect x="0" y="170" rx="4" ry="4" width="190" height="15" />
                <rect x="220" y="170" rx="4" ry="4" width="125" height="15" />

                <rect x="0" y="200" rx="4" ry="4" width="100" height="30" />
                <rect x="260" y="200" rx="4" ry="4" width="84" height="30" />

                <rect x="380" y="130" rx="4" ry="4" width="100" height="100" />


            </ContentLoader>
        </div>
    </div>
)