import React, { ReactElement } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import worshipPlaceStore from '../../stores/worshipPlace.store'
import sliderStore from '../../stores/slider.store'
import {
    getDataWorshipPlace,
    getDataSlider,
    getDataPinnedWorshipPlace,
    SLIDER_GET_DATA,
    SLIDER_GET_DATA_DETAIL,
    PINNED_WORSHIP_PLACE_GET_DATA,
    WORSHIP_PLACE_GET_DATA
} from '../../actions/index'
import { SliderLoader, CarouselLoader, List2Loader, ContentTumbnailLoader } from '../../components/loaders/index'
import { FlatList, TouchableOpacity, View } from 'react-native'
import * as IFaceInstitution from '../../stores/institution.interface.store'
import * as IFaceWorshipPlace from '../../stores/worshipPlace.interface.store'
import * as IFaceSlider from '../../stores/slider.interface.store'
import * as IFaceAuth from '../../stores/auth.interface.store'
import NoData from '../../components/nodatas/index'
import prettyNumber from '../../utils/pretty-number/index'
import truncateText from '../../utils/truncate/index'
import LoadMore from '../../components/loadmores/index'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import distanceMeter from './../../utils/distance-meter/index'
import { connect } from 'react-redux'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import Loaded from '../../components/loaded/index'

const SliderElement = (props: any): ReactElement => {
    let { data }: { data: IFaceSlider.Sliders } = props
    if (data) {
        if (data.models.length) {
            return (
                <div className="card card-style card-responsive-parent card-margin">
                    <Carousel showIndicators={false} showThumbs={false}>
                        {
                            data.models.map((val, data) => {
                                return (
                                    <div className="card card-responsive    ">
                                        <img src={val.image} className="img-fluid card-responsive rounded-cstm" />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
            )
        }
        return (<NoData />)
    }
    return (<SliderLoader />)
}

const PinnedWorshipPlaceElement = (props: any): ReactElement => {
    let { data }: { data: IFaceWorshipPlace.PinnedWorshipPlaces } = props
    if (data) {
        if (data.models.length) {
            return (
                <OwlCarousel
                    className="owl-theme"
                    loop
                    nav
                    autoWidth
                >
                    {
                        data.models.map((item, index) => {
                            return (
                                <div className="card card-style card-responsive-parent is-carousel card-auto card-margin" style={{ marginBottom: 18 }}>
                                    <div className="card card-responsive card-carousel rounded-cstm">
                                        <img src={item.thumbnail} className="img-fluid card-responsive rounded-cstm" />
                                    </div>
                                    <div className="content mt-3">
                                        <p className="font-600 mb-0 color-highlight">Masjid</p>
                                        <h3 className="font-700">{truncateText(item.title, 35)}</h3>
                                        <p className="mb-0"><i className="fa fa-map-marker" style={{ color: '#4ea57a' }}></i> &nbsp;{truncateText((item.address) ?? "", 39)}</p>
                                        <div className="divider mb-2"></div>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                            <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                            <TouchableOpacity>
                                                <Link to={`/worship-place/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                            </TouchableOpacity>
                                        </View>
                                    </div>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            )
        }
        return (<NoData />)
    }
    return (<CarouselLoader />)
}

const WorshipPlaceElement = (props: any): ReactElement => {
    let { data, location }: { data: IFaceWorshipPlace.WorshipPlaces, location: IFaceAuth.ILatLong } = props
    if (data) {
        if (data.models.length) {
            return (
                <FlatList
                    data={data.models}
                    onScrollEndDrag={() => console.log("end")}
                    renderItem={({ item }: { item: IFaceWorshipPlace.ModelWorshipPlaces }) => {
                        return (
                            <div className="card card-style card-responsive-parent card-auto card-margin">
                                <div className="card card-responsive rounded-cstm">
                                    <img src={item.thumbnail} className="card-responsive rounded-cstm" />
                                </div>
                                <div className="content mt-3">
                                    <p className="font-600 mb-0 color-highlight">Masjid</p>
                                    <h3 className="font-700">{truncateText(item.title, 40)}</h3>
                                    <p className="mb-0"><i className="fa fa-map-marker" style={{ color: '#4ea57a' }}></i> &nbsp;{truncateText(item.address ?? "", 53)}</p>
                                    {
                                        location.longitude && location.latitude ? (
                                            <p className="mb-1"><i className="fa fa-location-arrow" style={{ color: '#4ea57a' }}></i>&nbsp;{distanceMeter({ latitude: (item.latitude ?? 0), longitude: (item.longitude ?? 0) }, location)} KM dari tempat kamu</p>
                                        ) : (
                                            <p className="mb-1"><i className="fa fa-location-arrow"></i>&nbsp;Aktifkan lokasi untuk melihat jarak</p>
                                        )
                                    }
                                    <div className="divider mb-2"></div>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                        <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                        <TouchableOpacity>
                                            <Link to={`/worship-place/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                        </TouchableOpacity>
                                    </View>
                                </div>
                            </div>
                        )
                    }}
                    keyExtractor={(item: any) => item.id}
                />
            )
        }
        return (<NoData />)
    }
    return (<ContentTumbnailLoader />)
}

const DataEnd = (props: any): ReactElement => (
    <LoadMore data={props.data}>
        <ContentTumbnailLoader />
    </LoadMore>
)

const WorshipPlaceRender = (props: any): ReactElement => {

    let isFinished: boolean = false
    let isTotalData: number
    let isGotData: number
    let currentPage: number

    const [sliders, setSliders] = React.useState<IFaceSlider.Sliders | null | undefined>()
    const [pinnedWorships, setPinnedWorships] = React.useState<IFaceWorshipPlace.WorshipPlaces | null | undefined>()
    const [worshipPlace, setWorshipPlaces] = React.useState<IFaceInstitution.Institutions | null | undefined>()
    const [dataEnd, setDataEnd] = React.useState<string>("")

    let { isLatLong }: { isLatLong: IFaceAuth.ILatLong } = props

    const _onGetDataWorshipPlace = (): void => {
        let result = worshipPlaceStore.isGetWorshipPlace()
        isTotalData = result?.total ?? 0
        isGotData = result?.models.length ?? 0
        currentPage = result?.currentPage ?? 1
        setWorshipPlaces(result)
        setDataEnd("default")
        isFinished = true
    }

    const _onGetDataPinnedWorship = (): void => {
        let result = worshipPlaceStore.isGetPinnedWorshipPlace()
        setPinnedWorships(result)
    }

    const _onGetDataSlider = (): void => {
        let result = sliderStore.isGetSlider()
        setSliders(result)
    }

    React.useEffect(() => {
        worshipPlaceStore._addListener(WORSHIP_PLACE_GET_DATA, _onGetDataWorshipPlace)
        worshipPlaceStore._addListener(PINNED_WORSHIP_PLACE_GET_DATA, _onGetDataPinnedWorship)
        sliderStore._addListener(SLIDER_GET_DATA, _onGetDataSlider)

        window.addEventListener("scroll", _onScroll)

        let worshipPlace = worshipPlaceStore.isGetWorshipPlace()
        if (!worshipPlace) {
            getDataWorshipPlace()
        }
        else {
            _onGetDataWorshipPlace()
        }

        let pinnedWorship = worshipPlaceStore.isGetPinnedWorshipPlace()
        if (!pinnedWorship) {
            getDataPinnedWorshipPlace()
        }
        else {
            _onGetDataPinnedWorship()
        }

        getDataSlider("worship-place")

        return () => {
            worshipPlaceStore._removeListener(WORSHIP_PLACE_GET_DATA, _onGetDataWorshipPlace)
            worshipPlaceStore._removeListener(PINNED_WORSHIP_PLACE_GET_DATA, _onGetDataPinnedWorship)
            sliderStore._removeListener(SLIDER_GET_DATA_DETAIL, _onGetDataSlider)
            window.removeEventListener("scroll", _onScroll)
        }
    }, [])


    const __loadMore = async (): Promise<void> => {
        isFinished = false
        getDataWorshipPlace(currentPage + 1)
    }

    const _onScroll = (): void => {
        if ((isFinished) && ((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
            if (isGotData < isTotalData) {
                setDataEnd("loadmore")
                __loadMore()
            }
            else {
                setDataEnd("end")
            }
        }
    }

    return (
        <React.Fragment>
            <Seo
                title={`LIST MASJID BUTUH DONASI TERBARU - ${process.env.REACT_APP_NAME}`}
                desc='Kumpulan masjid yang membutuhkan donasi saat ini, baik untuk operasional maupun pembangunan'
                recent_link={window.location.href}
            />
            <SliderElement data={sliders} />
            <h2 className="color-highlight">Bantu tempat ibadah berikut</h2>
            <PinnedWorshipPlaceElement data={pinnedWorships} />
            <h2 className="color-highlight">Tempat ibadah</h2>
            <WorshipPlaceElement location={isLatLong} data={worshipPlace} />
            <DataEnd data={dataEnd} />
            {/* jangan dihapus */}
            <Loaded data={worshipPlace} />
        </React.Fragment>
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        isLatLong: state.latLong
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {}
}
export const WorshipPlace = connect(mapStateToProps, mapDispatchToProps)(WorshipPlaceRender);

export default WorshipPlaceRender