import { EventEmitter } from 'events'
import dispatcher from '../utils/flux/index'
import { GraphQL } from '../services/index';
import {
    PROGRAM_GET_DATA,
    PINNED_PROGRAM_GET_DATA,
    PROGRAM_GET_DATA_DETAIL,
    PROGRAM_GET_DATA_DEFAULT
} from '../actions/index'
import * as IFace from './program.interface.store'

class Program extends EventEmitter implements IFace.RootObject {
    programs: IFace.Programs | null
    pinnedPrograms: IFace.PinnedPrograms | null
    programDetail: IFace.ModelPrograms | null
    programDefault: IFace.ModelPrograms | null
    constructor() {
        super();
        this.programs = null;
        this.pinnedPrograms = null;
        this.programDetail = null;
        this.programDefault = null;
    }

    getProgramQuery = async (currentPage: number = 1): Promise<any> => {
        let query: string
        query = `{
                    programs(limit:10, currentPage:${currentPage}, filters:{sort:"title ASC"}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            desc,
                            thumbnail,
                            donate
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getProgramFromServer = async (currentPage?: number): Promise<void> => {
        let result: any;
        result = await this.getProgramQuery(currentPage);
        let { status, data }: { status: boolean, data: IFace.Program } = result;
        if (result && status) {
            let tmpData: IFace.Programs = data.programs
            if (this.programs) {
                tmpData.models = [...this.programs.models, ...tmpData.models]
            }
            this.isSetProgram(tmpData)
        }
        this.emit(PROGRAM_GET_DATA, { status })
    }

    isSetProgram = (data: IFace.Programs): void => {
        this.programs = data
    }

    isGetProgram = (): IFace.Programs | null => {
        return this.programs
    }

    getPinnedProgramQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    pinnedPrograms:programs(limit:5, filters:{pinned:1}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            desc,
                            thumbnail,
                            donate,
                            content_title,
                            content_desc
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getPinnedProgramFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getPinnedProgramQuery();
        let { status, data }: { status: boolean, data: IFace.PinnedProgram } = result;
        if (result && status) {
            let tmpData: IFace.PinnedPrograms = data.pinnedPrograms
            this.isSetPinnedProgram(tmpData)
        }
        this.emit(PINNED_PROGRAM_GET_DATA, { status })
    }

    isSetPinnedProgram = (data: IFace.Programs): void => {
        this.pinnedPrograms = data
    }

    isGetPinnedProgram = (): IFace.Programs | null => {
        return this.pinnedPrograms
    }

    getProgramDetailQuery = async (url: string): Promise<any> => {
        let query: string
        query = `{
                    program(url:"${url}"){
                        url_seo,
                        title,
                        donate,
                        thumbnail,
                        desc,
                        content_title,
                        content_desc,
                        ref_code
                    }
                }`;
        return await GraphQL(query)
    }

    getProgramDefaultQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    programDefault{
                        url_seo,
                        title,
                        donate,
                        thumbnail,
                        desc,
                        content_title,
                        content_desc
                    }
                }`;
        return await GraphQL(query)
    }

    getProgramDefaultFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getProgramDefaultQuery();
        let { status, data }: { status: boolean, data: IFace.ProgramDefault } = result;
        if (result && status) {
            this.isSetProgramDefault(data.programDefault)
        }
        this.emit(PROGRAM_GET_DATA_DEFAULT, { status })
    }

    isSetProgramDefault = (data: IFace.ModelPrograms): void => {
        this.programDefault = data
    }

    isGetProgramDefault = (): IFace.ModelPrograms | null => {
        return this.programDefault
    }

    getProgramDetailFromServer = async (url: string): Promise<void> => {
        let result: any;
        result = await this.getProgramDetailQuery(url);
        let { status, data }: { status: boolean, data: IFace.ProgramDetail } = result;
        if (result && status) {
            this.isSetProgramDetail(data.program)
        }
        this.emit(PROGRAM_GET_DATA_DETAIL, { status })
    }

    isSetProgramDetail = (data: IFace.ModelPrograms): void => {
        this.programDetail = data
    }

    isGetProgramDetail = (): IFace.ModelPrograms | null => {
        return this.programDetail
    }

    setProgramNull = (): void => {
        this.programs = null
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case PROGRAM_GET_DATA:
                this.getProgramFromServer(action.payload)
                break
            case PINNED_PROGRAM_GET_DATA:
                this.getPinnedProgramFromServer()
                break
            case PROGRAM_GET_DATA_DETAIL:
                this.getProgramDetailFromServer(action.payload)
                break
            case PROGRAM_GET_DATA_DEFAULT:
                this.getProgramDefaultFromServer()
                break
        }
    }
}

const programStore = new Program();
dispatcher.register(programStore.handleActions.bind(programStore))
export default programStore;