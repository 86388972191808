import dispatcher from './../utils/flux/index'
import { WORSHIP_PLACE_GET_DATA, WORSHIP_PLACE_GET_DATA_DETAIL, PINNED_WORSHIP_PLACE_GET_DATA } from './index'

export const getDataWorshipPlace = (currentPage?: number): void => {
    dispatcher.dispatch({
        type: WORSHIP_PLACE_GET_DATA,
        payload: currentPage
    })
}

export const getDataPinnedWorshipPlace = (): void => {
    dispatcher.dispatch({
        type: PINNED_WORSHIP_PLACE_GET_DATA
    })
}

export const getDataWorshipPlaceDetail = (url?: string): void => {
    dispatcher.dispatch({
        type: WORSHIP_PLACE_GET_DATA_DETAIL,
        payload: url
    })
}

