import React, { ReactElement, useRef } from 'react'
import { useParams } from "react-router"
import articleStore from '../../stores/article.store'
import paymentStore from '../../stores/payment.store'
import {
    getDataArticleDetail,
    ARTICLE_GET_DATA_DETAIL
} from '../../actions/index'
import { SliderLoader, ContentDetailLoader } from '../../components/loaders/index'
import { TouchableOpacity, View } from 'react-native'
import * as IFaceArticle from '../../stores/article.interface.store'
import { useGetSearchParam } from 'use-url-search-params-hooks';

// import * as IFaceSlider
import * as IFacePayment from '../../stores/payment.interface.store'
import * as IFaceAuth from '../../stores/auth.interface.store'
import NoData from '../../components/nodatas/index'
import Seo from '../../components/seo/index'
import prettyNumber from '../../utils/pretty-number/index'
import truncateText from '../../utils/truncate/index'
import htmlToString from '../../utils/html-to-string/index'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import PopUp from '../../components/popups/index'
import Loaded from '../../components/loaded/index'
import ShareContent from '../../components/share/index'
import { connect } from 'react-redux'
import { notify } from 'react-notify-toast';
import { Link, Redirect } from 'react-router-dom'
import parse from 'html-react-parser';
import { setCookie } from '../../utils/cookie'
import moment from 'moment'

const ArticleDetailHeader = (props: any) => {
    let { data }: { data: IFaceArticle.ModelArticles } = props
    if (data) {
        return (
            <div className="card-fixed">
                <div className="card p0 rounded-cstm main-item m-auto">
                    <OwlCarousel
                        className="owl-theme"
                        margin={10}
                        autoPlay
                        nav
                        autoWidth
                    >
                        <div className="item rounded-cstm">
                            {
                                data.thumbnail && (
                                    <React.Fragment>
                                        <img src={data.thumbnail} className="main-item rounded-cstm" />
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        )
    }
    if (data == null && typeof (data) != "undefined") {
        return <React.Fragment />
    }
    return (<SliderLoader />)
}

const ArticleDetailElement = (props: any): ReactElement => {
    let { data, enableInfaq, actionCallback }: { data?: IFaceArticle.ModelArticles | null, enableInfaq: boolean, actionCallback: (type: string, data?: any) => void } = props
    if (data) {
        let time: number = parseInt(data.created_at ?? "0");
        return (
            <React.Fragment>
                <div className="card card-clear" style={{ height: 235 }}></div>
                <div className="card card-style rounded-m pb-1">
                    <div className="drag-line"></div>
                    <div className="content">
                        <p className="font-600 mb-n1 color-highlight">Status: Publish</p>
                        <div className="divider mt-3 mb-2"></div>
                        <h1>{truncateText(data.title, 150, "...")}</h1>
                        <i className="fa fa-clock"></i> {moment.unix(time).format("DD/MM/YYYY, HH:MM:SS")}
                        <div className="mb-2 mt-1">
                            <article>
                                {parse(data.content ?? "")}
                            </article>
                        </div>
                    </div>
                </div>
                <div id="footer-bar" style={{ maxWidth: 480, margin: 'auto', padding: 8 }}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ width: '100%', marginRight: 16 }}>
                            <TouchableOpacity onPress={() => { actionCallback("share-btn") }} style={{ width: '100%', marginTop: -12 }}>
                                <button className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                                    SHARE
                                </button>
                            </TouchableOpacity>
                        </View>
                    </View>
                </div>
            </React.Fragment>
        )
    }
    if (data == null && typeof (data) != "undefined") {
        return (<NoData />)
    }
    return (
        <ContentDetailLoader>
            <div id="footer-bar" style={{ maxWidth: 480, margin: 'auto', padding: 8 }}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: 100, marginRight: 16 }}>
                        <button disabled={true} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                            SHARE
                        </button>
                    </View>
                    <View style={{ flex: 1 }}>
                        <button disabled={true} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                            DONASI SEKARANG!
                        </button>
                    </View>
                </View>
            </div>
        </ContentDetailLoader>
    )
}

export const ArticleDetailRender = (props: any): ReactElement => {
    let { auth }: { auth: IFaceAuth.DataLogin } = props;

    const [articles, setArticles] = React.useState<IFaceArticle.ModelArticles | undefined | null>()
    const [infaqPopUp, setInfaqPopUp] = React.useState<boolean>(false)
    const [sharePopUp, setSharePopUp] = React.useState<boolean>(false)
    const [paymentMethod, setPaymentMethod] = React.useState<IFacePayment.Payments | null>(null)
    const [enableInfaq, setEnableInfaq] = React.useState<boolean>(false)
    const [userInfo, setUserInfo] = React.useState<IFaceAuth.ILogin>()
    const [redirect, setRedirect] = React.useState<string>();
    const [refCode, setRefCode] = React.useState<string>();

    const { url_seo }: { url_seo: string } = useParams()
    const ref = useGetSearchParam(window.location.search, 'ref');

    const _onGetDetailArticle = (): void => {
        let result = articleStore.isGetArticleDetail()
        setArticles(result)
    }

    const _setUserInfo = (data: IFaceAuth.DataLogin): void => {
        setUserInfo(data.login)
    }

    React.useEffect(() => {
        goToTop()
        articleStore._addListener(ARTICLE_GET_DATA_DETAIL, _onGetDetailArticle)

        let result = articleStore.isGetArticleDetail()

        if (result && result.url_seo === url_seo) {
            _onGetDetailArticle()
        }
        else {
            getDataArticleDetail(url_seo)
        }

        return () => {
            articleStore._removeListener(ARTICLE_GET_DATA_DETAIL, _onGetDetailArticle)

        }
    }, [])

    React.useEffect(() => {
        _setUserInfo(auth)
    })

    const goToTop = () => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "share-btn":
                setSharePopUp(!sharePopUp)
                break
            default:
                console.log("Belum ada aksi")
                break
        }
    }

    return (
        <React.Fragment>
            <Seo
                is_article={true}
                title={articles?.title ?? null}
                image_uri={articles?.thumbnail}
                desc={truncateText(htmlToString(articles?.content ?? ''), 155, '...')}
                recent_link={window.location.href}
                date={(moment.utc(articles?.created_at)).toString()}
            />
            <PopUp title="Share" showPopUp={sharePopUp} onClose={() => { _action("share-btn") }}>
                <ShareContent
                    headerTitle={articles?.title}
                    url={window.location.href}
                    title={`Hello Orang Baik!`}
                    quote={`Ada info menarik nih tentang: ${articles?.title} `}
                    hashtag="#ringanin"
                    onClose={() => { _action("share-btn") }}
                />
            </PopUp>
            <ArticleDetailHeader data={articles} />
            <ArticleDetailElement
                enableInfaq={enableInfaq}
                data={articles}
                actionCallback={_action}
            />
            {/* jangan dihapus */}
            <Loaded data={articles} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment >
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {}
}
export const ArticleDetail = connect(mapStateToProps, mapDispatchToProps)(ArticleDetailRender);

