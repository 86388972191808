import React, { ReactElement } from 'react';
import { TouchableOpacity, View } from 'react-native'

export default (props: any): ReactElement => {
    let { data, children, componentEnd }: { data: string, children: ReactElement, componentEnd?: any } = props
    switch (data) {
        case "loadmore":
            return (
                <>
                    {children}
                </>
            )
            break;
        case "end":
            if (componentEnd) {
                return (
                    <>
                        {componentEnd}
                    </>
                )
            }
            return (
                <div className="card card-style card-responsive-parent card-auto card-margin" style={{ marginTop: 16 }}>
                    <div className="content">
                        <div className="d-flex">
                            <img src={`/assets/logo/icon.png`} className="rounded-sm" width="60" height="60" />
                            <div className="w-100 ml-3 pt-1">
                                <h4 className="font-700 color-highlight-2">Yeeey!</h4>
                                <p>Semua info di halaman ini sudah kamu lihat</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
            break;
        default:
            return (<></>)
            break;
    }

}