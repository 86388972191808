import dispatcher from './../utils/flux/index'
import { INSTITUTION_GET_DATA, TOP_INSTITUTION_GET_DATA, INSTITUTION_GET_DATA_DETAIL } from './index'

export const getDataInstitution = ({ currentPage, keyWords }: { currentPage?: number, keyWords?: string }): void => {
    dispatcher.dispatch({
        type: INSTITUTION_GET_DATA,
        payload: { currentPage, keyWords }
    })
}

export const getDataTopInstitution = (): void => {
    dispatcher.dispatch({
        type: TOP_INSTITUTION_GET_DATA
    })
}

export const getDataInstitutionDetail = (url?: string): void => {
    dispatcher.dispatch({
        type: INSTITUTION_GET_DATA_DETAIL,
        payload: url
    })
}

