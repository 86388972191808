import React from 'react'
import { View } from 'react-native'

interface IShareContentChild {
    title: string,
    desc: string,
    children: React.ReactElement,
    props?: any
}

const ShareContentChild = ({ title, desc, children, ...props }: IShareContentChild) => {
    return (
        <React.Fragment>
            <div className="divider mt-0 mb-1"></div>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ justifyContent: 'center', width: 50, height: 50, marginRight: 10, marginLeft: 0 }}>
                    {children}
                </View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
                    <span className="font-600 font-17 mb-n1 color-highlight mt-1">{title}</span>
                    <span>{desc}</span>
                </View>
                <View style={{ width: 10, justifyContent: 'center' }}>
                    <i className="fa fa-angle-right font-17" style={{ margin: 'auto' }}></i>
                </View>
            </View>
        </React.Fragment>
    )
}

export default ShareContentChild