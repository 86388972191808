import dispatcher from './../utils/flux/index'
import { SLIDER_GET_DATA, SLIDER_GET_DATA_DETAIL } from './index'

export const getDataSlider = (type?: string): void => {
    dispatcher.dispatch({
        type: SLIDER_GET_DATA,
        payload: { type: type }
    })
}

export const getDataSliderDetail = (url?: string): void => {
    dispatcher.dispatch({
        type: SLIDER_GET_DATA_DETAIL,
        payload: url
    })
}

