import { EventEmitter } from 'events'
import dispatcher from '../utils/flux/index'
import { GraphQL } from '../services/index';
import {
    MY_REFERRAL_GET_DATA,
    MY_REFERRAL_CREATE_DATA
} from '../actions/index'
import * as IFace from './referral.interface.store'

class Referral extends EventEmitter implements IFace.RootObject {
    referrals: IFace.Referrals | null
    createReferralResponse: IFace.IsReferralModel | null
    constructor() {
        super();
        this.referrals = null;
        this.createReferralResponse = null
    }

    getMyReferralQuery = async ({ currentPage, begin, end }: { currentPage?: number, begin: number, end: number }): Promise<any> => {
        let query: string
        query = `{
                    referrals(
                            limit:10, 
                            currentPage:${currentPage ?? 1}, 
                            filters:{
                                begin:${begin},
                                end:${end},
                                me:true,
                            }
                        ){
                        limit,
                        total,
                        currentPage,
                        successReferral,
                        models{
                            created_by,
                            amount_origin,
                            ref_amount,
                            status,
                            validated_at,
                            validated_by,
                            desc
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getMyReferralFromServer = async (payload: any): Promise<void> => {
        let result: any;
        result = await this.getMyReferralQuery(payload);
        let { status, data }: { status: boolean, data: IFace.Referral } = result;
        if (result && status) {
            let tmpData: IFace.Referrals = data.referrals
            if (this.referrals) {
                tmpData.models = [...this.referrals.models, ...tmpData.models]
            }
            this.isSetMyReferral(tmpData)
        }
        this.emit(MY_REFERRAL_GET_DATA, { status })
    }

    isSetMyReferral = (data: IFace.Referrals): void => {
        this.referrals = data
    }

    isGetMyReferral = (): IFace.Referrals | null => {
        return this.referrals
    }

    referralQuery = async (data: IFace.CreateReferral): Promise<any> => {
        let query: string
        query = `mutation{ 
            isReferral(data:${JSON.stringify(data).replace(/"(\w+)"\s*:/g, '$1:')}){
                status,
                message,
                referral_code
            }
        }`;
        return await GraphQL(query, "mutate")
    }

    createReferralToServer = async (dataReferral: IFace.CreateReferral): Promise<void> => {
        let result: any;
        result = await this.referralQuery(dataReferral);
        let { status, data }: { status: boolean, data: IFace.IsReferral } = result;
        if (result && status) {
            let tmpData: IFace.IsReferralModel = data.isReferral
            this.isSetResponseCreateReferral(tmpData)
        }
        this.emit(MY_REFERRAL_CREATE_DATA, { status })
    }

    isSetResponseCreateReferral = (data: IFace.IsReferralModel): void => {
        this.createReferralResponse = data
    }

    isGetResponseCreateReferral = (): IFace.IsReferralModel | null => {
        return this.createReferralResponse
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case MY_REFERRAL_GET_DATA:
                this.getMyReferralFromServer(action.payload)
                break
            case MY_REFERRAL_CREATE_DATA:
                this.createReferralToServer(action.payload)
                break
        }
    }
}

const referralStore = new Referral();
dispatcher.register(referralStore.handleActions.bind(referralStore))
export default referralStore;