import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    EmailIcon,
    LineIcon,
    LinkedinIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import { TouchableOpacity, Clipboard } from 'react-native'
import ShareContentChild from './child'
import { useParams } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { myReferralCreateData } from './../../actions/referral.action'
import { MY_REFERRAL_CREATE_DATA } from './../../actions/referral.actionType'
import referralStore from './../../stores/referral.store'
import { toast } from 'react-toastify';

interface IShareContent {
    url: string,
    title?: string,
    quote?: string,
    hashtag?: string,
    referral?: string | null,
    login?: any,
    headerTitle?: string,
    contentType?: string,
    onClose: () => void
}

const ShareContent = ({ headerTitle, url, contentType, title, quote, hashtag, referral, login, onClose }: IShareContent) => {
    const [isRef, setIsRef] = React.useState<boolean>(false)
    const [tmpUrl, setTmpUrl] = React.useState<string>(url)
    const { url_seo }: { url_seo: string } = useParams();
    const _copyToClipboard = (str: string) => {
        notify.show("Berhasil copy", "success", 3000)
        Clipboard.setString(str)
        onClose()
    }

    const _onReferralClick = () => {
        myReferralCreateData({ url: url_seo, contentType: contentType })
    }

    const _onCreateReferralGetData = () => {
        let result = referralStore.isGetResponseCreateReferral()
        if (result && result.status) {
            notify.show("berhasil membuat referral, share ulang!", "success");
            //ubah disini nantik
            window.location.reload()
        }
        else {
            notify.show(result?.message ?? "oups, mohon ulangi", "warning");
        }
    }

    React.useEffect(() => {
        if (isRef) {
            setTmpUrl(url + '?ref=' + referral);
        }
        else {
            setTmpUrl(url);
        }
    }, [isRef])

    React.useEffect(() => {
        referralStore._addListener(MY_REFERRAL_CREATE_DATA, _onCreateReferralGetData)
        return () => {
            referralStore._removeListener(MY_REFERRAL_CREATE_DATA, _onCreateReferralGetData)
        }
    }, [])

    return (
        <React.Fragment>
            <h4 className="mt-2 mb-2">{headerTitle ?? "-"}</h4>
            {
                login && referral && (
                    <div className="mb-2">
                        <input onChange={(e) => { setIsRef(!isRef) }} checked={isRef} id="ref" type="checkbox" />
                        &nbsp;
                        <label htmlFor="ref">share sebagai referral?</label>
                    </div>
                )
            }
            <FacebookShareButton
                url={tmpUrl}
                quote={quote}
                hashtag={hashtag}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="Facebook" desc="Bagikan ke sosial media Facebook">
                    <FacebookIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </FacebookShareButton>
            <TwitterShareButton
                url={tmpUrl}
                title={title}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="Twitter" desc="Bagikan ke sosial media Twitter">
                    <TwitterIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </TwitterShareButton>
            <WhatsappShareButton
                url={tmpUrl}
                title={title}
                separator={quote}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="WhatsApp" desc="Bagikan ke Whatsapp">
                    <WhatsappIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </WhatsappShareButton>
            <TelegramShareButton
                url={tmpUrl}
                title={title}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="Telegram" desc="Bagikan ke Telegram">
                    <TelegramIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </TelegramShareButton>
            <LineShareButton
                url={tmpUrl}
                title={title}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="Line" desc="Bagikan ke Line">
                    <LineIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </LineShareButton>
            <LinkedinShareButton
                url={tmpUrl}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="Linked In" desc="Bagikan ke LinkedIn">
                    <LinkedinIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </LinkedinShareButton>
            <EmailShareButton
                url={tmpUrl}
                style={{ flex: 1, width: '100%' }}
                onShareWindowClose={() => onClose()}
            >
                <ShareContentChild title="Email" desc="Bagikan melalui Email">
                    <EmailIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </EmailShareButton>
            <TouchableOpacity
                onPress={() => { _copyToClipboard(tmpUrl) }}
                style={{ flex: 1, width: '100%' }}
            >
                <ShareContentChild title="Salin Tautan" desc="Salin tautan">
                    <EmailIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                </ShareContentChild>
            </TouchableOpacity>
            {
                login && !referral && (
                    <TouchableOpacity
                        onPress={() => { _onReferralClick() }}
                        style={{ flex: 1, width: '100%' }}
                    >
                        <ShareContentChild title="Jadi Referral" desc="Klik untuk aktifkan referral">
                            <EmailIcon style={{ width: 45, height: 45, borderRadius: 8 }} />
                        </ShareContentChild>
                    </TouchableOpacity>
                )
            }
            <div className="divider mt-0 mb-4"></div>
        </React.Fragment>
    )
}

export default ShareContent