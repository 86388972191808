import dispatcher from '../utils/flux/index'
import { AUTH_REGISTER, AUTH_LOGIN, AUTH_LOGIN_GOOGLE, AUTH_FORGOT_PASSWORD, AUTH_ACTIVATION } from './index'

export const authLogin = (username: string, password: string): void => {
    dispatcher.dispatch({
        type: AUTH_LOGIN,
        payload: {
            username,
            password
        }
    })
}

export const authLoginGoogle = (tokenId: string): void => {
    dispatcher.dispatch({
        type: AUTH_LOGIN_GOOGLE,
        payload: tokenId
    })
}

export const forgotPassword = (username: string): void => {
    dispatcher.dispatch({
        type: AUTH_FORGOT_PASSWORD,
        payload: username
    })
}

export const authRegister = (data: any): void => {
    dispatcher.dispatch({
        type: AUTH_REGISTER,
        payload: data
    })
}

export const authActivation = (data: any): void => {
    dispatcher.dispatch({
        type: AUTH_ACTIVATION,
        payload: data
    })
}