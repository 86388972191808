import React from 'react'

type ILoaded = {
    data: any
}

const Loaded: React.FC<ILoaded> = (props): React.ReactElement => {
    let { data } = props
    if (typeof data == 'undefined') {
        return <></>
    }
    return <div className="is-loaded">&nbsp;</div>
}

export default Loaded;