import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { TouchableOpacity, View } from 'react-native'
import PopUp from '../../components/popups/index'
import * as REDUXTYPES from '../../reduxs/type-redux/index'
import { Redirect, Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import { getDataUser, USER_GET_DATA, updateDataUser, USER_UPDATE_DATA } from './../../actions/index'
import userStore from './../../stores/user.store'
import * as IUser from './../../stores/user.interface.store'
import { notify } from 'react-notify-toast'
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { readCookie, setCookie } from './../../utils/cookie/index'
import Seo from '../../components/seo/index'
import Loaded from '../../components/loaded/index'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const ComfirmPopUp = ({ action }: { action: (type: string) => void }): React.ReactElement => {
    return (
        <React.Fragment>
            <p className="mb-0">Simpan data baru kamu?</p>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 24 }}>
                <View style={{ width: 100, marginRight: 16 }}>
                    <button onClick={() => { action("cancel-save") }} className="btn btn-full gradient-orange font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                        Kembali
                        </button>
                </View>
                <View style={{ flex: 1 }}>
                    <button onClick={() => { action("data-save") }} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                        Simpan
                        </button>
                </View>
            </View>
        </React.Fragment>
    )
}

const UploadImage = ({ action, callBack }: { action: (type: string) => void, callBack: (uri: any) => void }): React.ReactElement => {
    const [files, setFiles] = React.useState([]);
    const _onError = () => {
        notify.show("Sistem sibuk", "error")
    }
    const _onSuccess = (response: string) => {
        callBack(response)
    }
    return (
        <React.Fragment>
            <Seo
                title={`UBAH PROFIL - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={false}
                server={{
                    url: process.env.REACT_APP_UPLOAD_IMAGE,
                    process: {
                        url: '/upload-image',
                        method: 'POST',
                        // headers: {
                        //     'x-customheader': 'Hello World'
                        // },
                        withCredentials: false,
                        onload: (response) => {
                            _onSuccess(JSON.parse(response))
                            return JSON.parse(response)
                        },
                        onerror: (response) => {
                            _onError()
                            return JSON.parse(response)
                        },
                        ondata: (formData) => {
                            return formData;
                        }
                    },
                }}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif']}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 24 }}>
                <View style={{ width: '100%', marginRight: 16 }}>
                    <button onClick={() => { action("cancel-upload-photo") }} className="btn btn-full gradient-orange font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                        Kembali
                        </button>
                </View>
            </View>
        </React.Fragment>
    )
}

export const UpdateProfileRender = (props: any): ReactElement => {
    const { setAuth }: { setAuth: (payload: null) => void } = props

    const [popUpConfirm, setPopUpConfirm] = React.useState<boolean>(false)
    const [popUpUploadPhoto, setPoppopUpUploadPhoto] = React.useState<boolean>(false)

    const [redirect, setRedirect] = React.useState<string>()
    const [username, setUsername] = React.useState<string>("")
    const [fullName, setFullName] = React.useState<string>("")
    const [phone, setPhone] = React.useState<string>("")
    const [image, setImage] = React.useState<string>("")
    const [disabled, setDisabled] = React.useState<boolean>(true)

    const [, forceUpdate] = React.useState<number>();
    const simpleValidator = React.useRef(new SimpleReactValidator())

    let usernameValidator = simpleValidator.current.message('username', username, 'required|string|email');
    let fullNameValidator = simpleValidator.current.message('fullName', fullName, 'required|string');
    let phoneValidator = simpleValidator.current.message('phone', phone, 'required|numeric');

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "confirm-save":
                _confirmSave()
                break
            case "upload-photo":
                setPoppopUpUploadPhoto(true)
                break
            case "cancel-upload-photo":
                setPoppopUpUploadPhoto(false)
                break
            case "cancel-save":
                setPopUpConfirm(false)
                break
            case "data-save":
                setPopUpConfirm(false)
                _onSaveData()
                break;
            case "data-image-save":
                setPoppopUpUploadPhoto(false)
                _onSaveData(data)
                break;
        }
    }

    const _confirmSave = () => {
        forceUpdate(0)
        if (simpleValidator.current.allValid()) {
            setPopUpConfirm(true)
        }
        else {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }

    const _onGetBiodata = () => {
        let result = userStore.isGetBiodata()
        if (result) {
            setImage(result.image)
            setFullName(result.full_name)
            setUsername(result.email)
            setPhone(result.phone)
        }
        setDisabled(false)
    }

    const _onUpdatedBiodata = (status: boolean) => {
        if (status) {
            notify.show("Perubahan data berhasil", "success")
            //set auth ulang

            let readCookies = readCookie(`after-save`).replace("=", "")
            if (readCookies) {
                setCookie("after-save", "", -1)
                window.location.href = `/${readCookies}`
            }
            else {
                window.location.reload()
            }
        }
        else {
            notify.show("Sistem sibuk", "error")
        }
        _onGetBiodata()
    }

    const _onSaveData = (isImage?: string) => {
        let data: IUser.DataBiodata | IUser.DataImage
        setDisabled(true)
        if (isImage) {
            data = {
                image: isImage
            }
        }
        else {
            data = {
                username,
                full_name: fullName,
                phone
            }
        }
        updateDataUser(data)
    }

    React.useEffect(() => {
        userStore._addListener(USER_GET_DATA, _onGetBiodata)
        userStore._addListener(USER_UPDATE_DATA, _onUpdatedBiodata)
        getDataUser()
        return () => {
            userStore._removeListener(USER_GET_DATA, _onGetBiodata)
            userStore._removeListener(USER_UPDATE_DATA, _onUpdatedBiodata)
        }
    }, [])

    return (
        <React.Fragment>
            <PopUp title="Konfirmasi" showPopUp={popUpConfirm} onClose={() => { _action("cancel-save") }}>
                <ComfirmPopUp action={_action} />
            </PopUp>
            <PopUp title="Unggah Foto" showPopUp={popUpUploadPhoto} onClose={() => { _action("cancel-upload-photo") }}>
                <UploadImage action={_action} callBack={(response) => {
                    _action("data-image-save", response)
                }} />
            </PopUp>
            <div className="card card-style rounded-m pb-1">
                <div className="content">
                    <h4 className="color-highlight">Update Profil</h4>
                    <div className="divider mt-2 mb-2"></div>

                    <div className="input-style has-icon input-style-1 input-required">
                        <i className="input-icon fa fa-envelope"></i>
                        <span>Email (juga menjadi username)</span>
                        <em>(required)</em>
                        <input disabled={disabled} onChange={(e) => setUsername(e.target.value)} type="email" placeholder="Email (juga menjadi username)" value={username} />
                        <p style={{ color: 'red' }} className={usernameValidator ? "mb-n2 mt-n2" : "mb-2"}>{usernameValidator}</p>
                    </div>

                    <div className="input-style has-icon input-style-1 input-required">
                        <i className="input-icon fa fa-user"></i>
                        <span>Nama Lengkap</span>
                        <em>(required)</em>
                        <input disabled={disabled} onChange={(e) => setFullName(e.target.value)} type="text" placeholder="Nama Lengkap" value={fullName} />
                        <p style={{ color: 'red' }} className={fullNameValidator ? "mb-n2 mt-n2" : "mb-2"}>{fullNameValidator}</p>
                    </div>

                    <div className="input-style has-icon input-style-1 input-required">
                        <i className="input-icon fa fa-phone"></i>
                        <span>Nomor Handphone</span>
                        <em>(required)</em>
                        <input disabled={disabled} onChange={(e) => setPhone(e.target.value)} type="number" placeholder="Nomor Hape" value={phone} />
                        <p style={{ color: 'red' }} className={phoneValidator ? "mb-n2 mt-n2" : "mb-2"}>{phoneValidator}</p>
                    </div>

                    <TouchableOpacity onPress={() => { _action("confirm-save") }}>
                        <button className="btn gradient-blue font-13 font-600 rounded-sm mt-2">Simpan</button>
                    </TouchableOpacity>
                </div>
            </div>

            <div className="card card-style rounded-m pb-1">
                <div className="content">
                    <h4 className="color-highlight">Foto Profil</h4>
                    <div className="divider mt-2 mb-2"></div>
                    <div className="d-flex">
                        <img src={image ?? "/theme/images/pictures/17s.jpg"} className="logo-institution rounded-s" style={{ margin: 'auto' }} />
                    </div>
                    <div className="divider mt-2 mb-2"></div>
                    <TouchableOpacity onPress={() => { _action("upload-photo") }}>
                        <button className="btn gradient-blue font-13 font-600 rounded-sm mt-2">Ubah Foto</button>
                    </TouchableOpacity>
                </div>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        isLatLong: state.latLong
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setAuth: (payload: null) => dispatch({ type: REDUXTYPES.AUTH_LOGIN, payload }),
    }
}
export const UpdateProfile = connect(mapStateToProps, mapDispatchToProps)(UpdateProfileRender);

export default UpdateProfile
