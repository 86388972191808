import dispatcher from './../utils/flux/index'
import { PAYMENT_METHOD_GET_DATA, PAYMENT_CREATE_DATA } from './index'
import { PAYMENT_GET_DATA_DETAIL } from './payment.actionType'

export const getDataPaymentMethod = (): void => {
    dispatcher.dispatch({
        type: PAYMENT_METHOD_GET_DATA
    })
}

export const createPayment = (data: any): void => {
    dispatcher.dispatch({
        type: PAYMENT_CREATE_DATA,
        payload: data
    })
}

export const getDataPaymentDetail = (code: string): void => {
    dispatcher.dispatch({
        type: PAYMENT_GET_DATA_DETAIL,
        payload: code
    })
}


