import React, { ReactElement } from 'react';
import Seo from '../../components/seo/index'
import Loaded from '../../components/loaded/index'
import { Link } from 'react-router-dom'

export const NotFound = (): ReactElement => {
    return (
        <React.Fragment>
            <Seo
                title={`404 - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <div
                className="card card-style bg-1"
                style={{ backgroundImage: `url("https://picsum.photos/200/300")` }}
                data-card-height="cover-card"
            >
                <div className="card-top text-center">
                    <h1 className="color-white text-center font-22 pt-4 mb-0">Page Not Found</h1>
                    <h1 className="color-white font-40">404 Error</h1>
                </div>
                <div className="card-bottom text-center">
                    <p className="color-white font-15 opacity-70 px-4">Sorry, the page you're looking for is not here. We suggest checking out the homepage. It's awesome.</p>
                    <Link to={process.env.PUBLIC_URL} className="btn btn-m btn-center-m bg-white color-black font-700 mb-4">Back Home</Link>
                </div>

                <div className="card-overlay bg-gradient"></div>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
        </React.Fragment>
    );
}
