import React, { ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { TouchableOpacity } from 'react-native'
import SimpleReactValidator from 'simple-react-validator'
import { forgotPassword } from './../../actions/auth.action'
import authStore from './../../stores/auth.store'
import { notify } from 'react-notify-toast';
import * as IFaceAuth from './../../actions/auth.actionType'
import Seo from '../../components/seo/index'
import Loaded from '../../components/loaded/index'

export const ForgotPassword = (): ReactElement => {
    const [username, setUsername] = React.useState<string>("");
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [redirect, setRedirect] = React.useState<string>();
    const [, forceUpdate] = React.useState<number>();
    const simpleValidator = React.useRef(new SimpleReactValidator())
    let usernameValidator = simpleValidator.current.message('username', username, 'required|string|email');


    const _onForgotPasswordButtonPress = () => {
        forceUpdate(0)
        if (simpleValidator.current.allValid()) {
            forgotPassword(username)
            setDisabled(true)
        }
        else {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }

    const _onDataForgotPassword = async (status: boolean): Promise<void> => {
        setDisabled(false)
        let msg: string = "Permintaan gagal";
        if (status) {
            let result = authStore.isDataForgotPassword();
            if (result && result?.status) {
                notify.show(result.message ?? "Check email kamu", "success", 5000)
                //redirect
                setRedirect("/login");
            }
            msg = result?.message ?? "Sistem sibuk";
        }
        notify.show(msg, "error", 3000)
    }

    React.useEffect(() => {
        authStore._addListener(IFaceAuth.AUTH_FORGOT_PASSWORD, _onDataForgotPassword)
        return () => authStore._removeListener(IFaceAuth.AUTH_FORGOT_PASSWORD, _onDataForgotPassword)
    }, [])

    return (
        <React.Fragment>
            <Seo
                title={`LUPA PASSWORD - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <div className="card card-style">
                <div className="content">
                    <h1 className="font-30 ml-n1">&nbsp;Forgot Password</h1>
                    <p>
                        Enter your username for get token reset password.
                        </p>
                    <div className="input-style has-icon input-style-1 input-required">
                        <i className="input-icon fa fa-user"></i>
                        <span>Username (email)</span>
                        <em>(required)</em>
                        <input onChange={(e) => setUsername(e.target.value)} type="name" placeholder="Username (email)" />
                        <p style={{ color: 'red' }} className={usernameValidator ? "mb-n2 mt-n2" : "mb-2"}>{usernameValidator}</p>
                    </div>
                    <TouchableOpacity onPress={_onForgotPasswordButtonPress}>
                        <button disabled={disabled} className="btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s">Req New Password</button>
                    </TouchableOpacity>
                    <div className="row pt-3 mb-3">
                        <div className="col-6 text-left">
                            <Link to="/login" className="color-highlight">Login?</Link>
                        </div>
                        <div className="col-6 text-right">
                            <Link to="/register" className="color-highlight">Create Account?</Link>
                        </div>
                    </div>
                </div>
                {/* <div data-menu-load="menu-footer.html"></div> */}
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    );
}
