import React, { ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { TouchableOpacity } from 'react-native'
import { GoogleLogin } from 'react-google-login';
import { notify } from 'react-notify-toast';
import { authLoginGoogle, authRegister } from './../../actions/auth.action'
import authStore from './../../stores/auth.store'
import * as IFaceAuth from './../../actions/auth.actionType'
import { readLocalStorage, setLocalStorage, removeLocalStorage } from './../../utils/local-storage/index'
import SimpleReactValidator from 'simple-react-validator'
import * as IAuth from './../../stores/auth.interface.store'
import Seo from '../../components/seo/index'
import Loaded from '../../components/loaded/index'
export const Register = (): ReactElement => {
    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [retypePassword, setRetypePassword] = React.useState<string>();
    const [redirect, setRedirect] = React.useState<string>();
    const [disabled, setDisabled] = React.useState<boolean>(true);

    const [, forceUpdate] = React.useState<number>();
    const simpleValidator = React.useRef(new SimpleReactValidator())

    let usernameValidator = simpleValidator.current.message('username', username, 'required|string|email');
    let passwordValidator = simpleValidator.current.message('password', password, 'required|string|min:6|max:15');
    let retypePasswordValidator = simpleValidator.current.message('retypePassword', retypePassword, `required|string|in:${password}`);


    const responseGoogle = (response: any) => {
        let { error, tokenId }: { error: any, tokenId: string } = response
        if (error) {
            notify.show("Sistem sibuk", "warning", 3000)
        }
        if (tokenId) {
            notify.show("Autentikasi berhasil", "success", 3000)
            authLoginGoogle(tokenId);
        }
    }

    const _getRedirect = async (): Promise<string | null> => {
        let isRedirect = await readLocalStorage("redirect")
        await removeLocalStorage("redirect")
        return isRedirect
    }

    const _onDataLogin = async (status: boolean): Promise<void> => {
        setDisabled(false)
        let msg: string = "Sistem sibuk";
        if (status) {
            let result = authStore.isDataUser();
            if (result?.status && setLocalStorage('auth', result.token)) {
                notify.show("Selamat datang!", "success", 7000)
                //redirect
                let redirect = (await _getRedirect() ?? "/")
                // setRedirect(String(redirect));
                window.location.href = String(redirect)
            }
            msg = result?.message ?? "Sistem sibuk";
        }
        notify.show(msg, "error", 3000)
    }

    const _onRegisterButtonPress = () => {
        forceUpdate(0)
        if (simpleValidator.current.allValid()) {
            let data: IAuth.ParamsRegister
            data = { username, password }
            authRegister(data);
            setDisabled(true)
        }
        else {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }

    const _onDataRegister = async (status: boolean): Promise<void> => {
        setDisabled(false)
        let msg: string = "Permintaan gagal";
        if (status) {
            let result = authStore.isDataRegister();
            if (result && result?.status) {
                notify.show("Silahkan cek email kamu!", "success", 5000)
                //redirect
                setRedirect("/login");
            }
            msg = result?.message ?? "Sistem sibuk";
        }
        notify.show(msg, "error", 3000)
    }

    React.useEffect(() => {
        setDisabled(false)
        authStore._addListener(IFaceAuth.AUTH_REGISTER, _onDataRegister)
        authStore._addListener(IFaceAuth.AUTH_LOGIN_GOOGLE, _onDataLogin)

        return () => {
            authStore._removeListener(IFaceAuth.AUTH_REGISTER, _onDataRegister)
            authStore._removeListener(IFaceAuth.AUTH_LOGIN_GOOGLE, _onDataLogin)
        }
    }, [])

    return (
        <React.Fragment>
            <Seo
                title={`MENDAFTAR - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <div className="card card-style">
                <div className="content">
                    <h1 className="font-30 ml-n1">&nbsp;Register</h1>
                    <p>Enter your credentials below to sign into your account.</p>
                    <div className="input-style has-icon input-style-1 input-required">
                        <i className="input-icon fa fa-user"></i>
                        <span>Email</span>
                        <em>(required)</em>
                        <input onChange={(e) => setUsername(e.target.value)} type="email" placeholder="Email" />
                        <p style={{ color: 'red' }} className={usernameValidator ? "mb-n2 mt-n2" : "mb-2"}>{usernameValidator}</p>
                    </div>
                    <div className="input-style has-icon input-style-1 input-required mt-3">
                        <i className="input-icon fa fa-lock"></i>
                        <span>Password</span>
                        <em>(required)</em>
                        <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                        <p style={{ color: 'red' }} className={passwordValidator ? "mb-n2 mt-n2" : "mb-2"}>{passwordValidator}</p>
                    </div>
                    <div className="input-style has-icon input-style-1 input-required mt-3">
                        <i className="input-icon fa fa-lock"></i>
                        <span>Retype Password</span>
                        <em>(required)</em>
                        <input onChange={(e) => setRetypePassword(e.target.value)} type="password" placeholder="Retype Password" />
                        <p style={{ color: 'red' }} className={retypePasswordValidator ? "mb-n2 mt-n2" : "mb-2"}>{retypePasswordValidator && ("Password not match")}</p>
                    </div>
                    <TouchableOpacity onPress={_onRegisterButtonPress}>
                        <button className="btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s">Register</button>
                    </TouchableOpacity>
                    <p className="text-center mt-2 mb-n1">OR</p>
                    <div className="row pt-3 mb-3 ml-0 mr-0">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_OAUTH + ""}
                            render={renderProps => (
                                <button className="btn btn-full btn-l font-600 font-13 bg-orange-light rounded-s" onClick={renderProps.onClick} disabled={renderProps.disabled ?? disabled} style={{ width: '100%' }}>Register with Google</button>
                            )}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            uxMode={"redirect"}
                        />
                    </div>
                    <div className="row pt-3 mb-3">
                        <div className="col-6 text-left">
                            <Link to="/forgot-password" className="color-highlight">Forgot Password?</Link>
                        </div>
                        <div className="col-6 text-right">
                            <Link to="/login" className="color-highlight">Log In</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    );
}
