import ApolloClient from "apollo-boost"
import { readLocalStorage } from './../../utils/local-storage/index'
export const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_API,
    request: async (operation) => {
        const token = await readLocalStorage("auth")
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        })
    }
});
