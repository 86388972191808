import React, { Suspense } from 'react';
import MainRouter from './app/routers/index'
// import './App.css';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { mainStore } from './app/reduxs/index';
import LoadingBar from 'react-redux-loading-bar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notifications, { notify } from 'react-notify-toast';

function App() {
    return (
        <Provider store={mainStore}>
            <Router>
                <Suspense fallback={<div>Memuat Ulang...</div>}>
                    <LoadingBar style={{ zIndex: 999, position: 'fixed' }} />
                    <ToastContainer />
                    <Notifications />
                    <MainRouter />
                </Suspense>
            </Router>
        </Provider>
    );
}

export default App;
