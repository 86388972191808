import React, { ReactElement, useRef } from 'react'
import { useParams } from "react-router"
import institutionStore from './../../stores/institution.store'
import paymentStore from './../../stores/payment.store'
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import {
    createPayment,
    getDataInstitutionDetail,
    getDataPaymentMethod,
    INSTITUTION_GET_DATA_DETAIL,
    PAYMENT_CREATE_DATA,
    PAYMENT_METHOD_GET_DATA
} from './../../actions/index'
import { SliderLoader, ContentDetailLoader } from './../../components/loaders/index'
import { FlatList, TouchableOpacity, View } from 'react-native'
import * as IFaceInstitution from './../../stores/institution.interface.store'
import * as IFacePayment from './../../stores/payment.interface.store'
import * as IFaceAuth from './../../stores/auth.interface.store'
import NoData from './../../components/nodatas/index'
import prettyNumber from './../../utils/pretty-number/index'
import getDaysRange from './../../utils/get-days-range/index'
import truncateText from './../../utils/truncate/index'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import PopUp from './../../components/popups/index'
import ShareContent from './../../components/share/index'
import { connect } from 'react-redux'
import { notify } from 'react-notify-toast';
import { Link, Redirect } from 'react-router-dom'
import InfaqContent from './../../components/dontates/index'
import { useGetSearchParam } from 'use-url-search-params-hooks';
import { setCookie, readCookie } from './../../utils/cookie'
import moment from 'moment'
import parse from 'html-react-parser';
import Loaded from '../../components/loaded/index'


interface IDataContent {
    url_seo: string,
    ref: string,
    created_at: number
}

const InstitutionDetailHeader = (props: any) => {
    let { data }: { data: IFaceInstitution.ModelInstitutions } = props
    if (data) {
        return (
            <div className="card-fixed">
                <div className="card p0 rounded-cstm main-item m-auto">
                    <OwlCarousel
                        className="owl-theme"
                        margin={10}
                        autoPlay
                        nav
                        autoWidth
                    >
                        <div className="item rounded-cstm">
                            {
                                data.thumbnail && (
                                    <React.Fragment>
                                        <img src={data.thumbnail} className="main-item rounded-cstm" />
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        )
    }
    if (data == null && typeof (data) != "undefined") {
        return <React.Fragment />
    }
    return (<SliderLoader />)
}

const InstitutionDetailElement = (props: any): ReactElement => {
    let { data, enableInfaq, actionCallback }: { data?: IFaceInstitution.ModelInstitutions | null, enableInfaq: boolean, actionCallback: (type: string, data?: any) => void } = props
    if (data) {
        return (
            <React.Fragment>
                <div className="card card-clear" style={{ height: 235 }}></div>
                <div className="card card-style rounded-m pb-1">
                    <div className="drag-line"></div>
                    <div className="content">
                        <p className="font-600 mb-n1 color-highlight">Status: Terverifikasi</p>
                        <div className="divider mt-3 mb-2"></div>
                        <h1>{truncateText(data.title, 150, "...")}</h1>
                        <div className="mb-2 mt-1">
                            {truncateText((data.tagline ?? ""), 112, "...")}
                        </div>
                        <div className="divider mt-1 mb-1"></div>
                        <div className="list-group list-custom-large check-visited mb-2">
                            <a className="">
                                <i className="fa fa-dollar-sign font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                                <span className="font-20 color-highlight-2">{prettyNumber(data.donate, false, 1000000, 0, "0")} IDR</span>
                                <strong>Total infak bulan ini</strong>
                                <i className="fa fa-angle-right font-14"></i>
                            </a>
                        </div>
                        {
                            data.content_title && data.content_desc && (
                                <React.Fragment>
                                    <h2>{data.content_title}</h2>
                                    <div className="divider mt-2 mb-1"></div>
                                    <article>
                                        {parse(data.content_desc)}
                                    </article>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
                <div className="card card-style rounded-m pb-1">
                    <div className="content">
                        <h2 className="color-highlight">Detail Instansi</h2>
                        <div className="divider mt-2 mb-2"></div>
                        <div className="d-flex">
                            <img src={data.logo ?? "/theme/images/pictures/17s.jpg"} className="logo-institution rounded-s" />
                            <div className="w-100 ml-3 pt-1">
                                <h2 className="font-500">{truncateText(data.title, 35)}</h2>
                                <p className="pb-1">{truncateText((data.tagline ?? ""), 90)}</p>
                            </div>
                        </div>
                        <div className="divider mb-1 mt-2"></div>
                        <div className="list-group list-custom-large check-visited">
                            <a className="visited-link">
                                <i className="fa fa-map-marker font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                                <span>Alamat</span>
                                <strong>{truncateText((data.address ?? "-"), 62, "...", "-")}</strong>
                                <i className="fa fa-angle-right"></i>
                            </a>
                            <a className="visited-link">
                                <i className="fas fa-phone font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                                <span>Nomor Telp</span>
                                <strong>{data.telp ?? "-"}</strong>
                                <i className="fa fa-angle-right"></i>
                            </a>

                            <a className="visited-link">
                                <i className="fas fa-envelope font-14 bg-blue-dark color-white rounded-sm shadow-xl"></i>
                                <span>Email</span>
                                <strong>{data.email ?? "-"}</strong>
                                <i className="fa fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="footer-bar" style={{ maxWidth: 480, margin: 'auto', padding: 8 }}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ width: 100, marginRight: 16 }}>
                            <TouchableOpacity onPress={() => { actionCallback("share-btn") }} style={{ width: '100%', marginTop: -12 }}>
                                <button className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                                    SHARE
                                </button>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flex: 1 }}>
                            <TouchableOpacity onPress={() => { actionCallback("infaq-btn") }} style={{ width: '100%', marginTop: -12 }}>
                                <button disabled={!enableInfaq} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                                    INFAK SEKARANG!
                                </button>
                            </TouchableOpacity>
                        </View>
                    </View>
                </div>
            </React.Fragment>
        )
    }
    if (data == null && typeof (data) != "undefined") {
        return (<NoData />)
    }
    return (
        <ContentDetailLoader>
            <div id="footer-bar" style={{ maxWidth: 480, margin: 'auto', padding: 8 }}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: 100, marginRight: 16 }}>
                        <button disabled={true} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                            SHARE
                        </button>
                    </View>
                    <View style={{ flex: 1 }}>
                        <button disabled={true} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                            INFAK SEKARANG!
                        </button>
                    </View>
                </View>
            </div>
        </ContentDetailLoader>
    )
}

export const InstitutionDetailRender = (props: any): ReactElement => {
    let { auth }: { auth: IFaceAuth.DataLogin } = props;

    const [institutions, setInstitutions] = React.useState<IFaceInstitution.ModelInstitutions | undefined | null>()
    const [infaqPopUp, setInfaqPopUp] = React.useState<boolean>(false)
    const [sharePopUp, setSharePopUp] = React.useState<boolean>(false)
    const [paymentMethod, setPaymentMethod] = React.useState<IFacePayment.Payments | null>(null)
    const [enableInfaq, setEnableInfaq] = React.useState<boolean>(false)
    const [userInfo, setUserInfo] = React.useState<IFaceAuth.ILogin>()
    const [redirect, setRedirect] = React.useState<string>();
    const [refCode, setRefCode] = React.useState<string>();

    const { url_seo }: { url_seo: string } = useParams()
    const ref = useGetSearchParam(window.location.search, 'ref');

    const _onGetDataDetailInstitution = (): void => {
        let result = institutionStore.isGetInstitutionDetail()
        setInstitutions(result)
    }

    const _onGetDataPaymentMethod = (status: boolean) => {
        let result = paymentStore.isGetPayment()
        setPaymentMethod(result)
        setEnableInfaq(status)
    }

    const _setUserInfo = (data: IFaceAuth.DataLogin): void => {
        setUserInfo(data.login)
    }

    const _onPaymentGetData = () => {
        setEnableInfaq(true)
        let result = paymentStore.isGetResponseCreatePayment()
        if (result && result.status) {
            notify.show("Transaksi diproses", "success", 3000)
            if (result.redirect == "detail") {
                setRedirect(`/payment/detail/${result.internal_payment_code}`);
            }
            else if (result.redirect == "done") {
                setRedirect(`/payment/status/${result.internal_payment_code}`);
            }
            else {
                window.location.reload()
            }
        }
        else {
            notify.show(result?.message ?? "Sistem sibuk", "error", 3000)
        }
    }

    const manageRef = (url_seo: string, ref: string): IDataContent[] => {
        let refs = readCookie("ref")
        let tmpRefs: IDataContent[]
        if (refs) {
            tmpRefs = JSON.parse(refs.replace("=", ""))
            let index = tmpRefs.findIndex(x => x.url_seo === url_seo && x.ref === ref)
            if (index != -1) {
                //delete yang lama
                tmpRefs.splice(index, 1);
            }
            //masukkan paling belakang
            tmpRefs.push({ url_seo, ref, created_at: new Date().getTime() })
        }
        else {
            tmpRefs = [{ url_seo, ref, created_at: new Date().getTime() }]
        }
        return tmpRefs
    }

    const readRefFromCookie = (): void => {
        let refs = readCookie("ref")
        if (refs) {
            let isRefs: IDataContent[] = JSON.parse(refs.replace("=", ""))
            let refCheck = isRefs.filter(obj => obj.url_seo === url_seo);
            if (refCheck.length) {
                let created_at = refCheck[refCheck.length - 1].created_at
                let now_ = new Date().getTime()
                //jika tanggal akses ref <=30 hari, ref berlaku, jika tidak maka biarkan null
                if (getDaysRange(created_at, now_) <= 30) {
                    //set ref ke state, supaya component update dan passing ke popup donasi
                    setRefCode(refCheck[refCheck.length - 1].ref)
                }
            }
        }
    }

    React.useEffect(() => {
        if (ref) {
            let res = manageRef(url_seo, ref);
            setCookie("ref", JSON.stringify(res), 30)
            //redirect to normal url
            setRedirect(`/institution/${url_seo}`)
        }
    }, [])

    React.useEffect(() => {
        goToTop()
        getDataPaymentMethod()
        //check cookie
        readRefFromCookie()
        institutionStore._addListener(INSTITUTION_GET_DATA_DETAIL, _onGetDataDetailInstitution)
        paymentStore._addListener(PAYMENT_METHOD_GET_DATA, _onGetDataPaymentMethod)
        paymentStore._addListener(PAYMENT_CREATE_DATA, _onPaymentGetData)

        let result = institutionStore.isGetInstitutionDetail()

        if (result && result.url_seo === url_seo) {
            _onGetDataDetailInstitution()
        }
        else {
            getDataInstitutionDetail(url_seo)
        }

        return () => {
            institutionStore._removeListener(INSTITUTION_GET_DATA_DETAIL, _onGetDataDetailInstitution)
            paymentStore._removeListener(PAYMENT_METHOD_GET_DATA, _onGetDataPaymentMethod)
            paymentStore._removeListener(PAYMENT_CREATE_DATA, _onPaymentGetData)
        }
    }, [])

    React.useEffect(() => {
        _setUserInfo(auth)
    })

    const goToTop = () => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    }

    const _onButtonInfaqPress = (data: IFacePayment.DataInfaq): void => {
        setEnableInfaq(false)
        let msg = "Infak bersama: " + institutions?.title;
        data = { ...data, message: msg, paymentMessage: msg, description: msg };
        if (userInfo) {
            let phone = userInfo.phone;
            let alias = userInfo.full_name;
            let email = userInfo.email;
            data = {
                ...data,
                customerMobilePhone: (data.customerMobilePhone && data.customerMobilePhone != phone ? data.customerMobilePhone : phone),
                email: email,
                alias: alias,
            }
        }
        //data siap dikirim ya beb...
        createPayment(data)
    }

    const _onInfaqPress = () => {
        if (userInfo) {
            if (userInfo.email && userInfo.full_name && userInfo.phone) {
                setInfaqPopUp(!infaqPopUp)
            }
            else {
                setCookie(`after-save`, `institution/${url_seo}`, 1)
                notify.show("Lengkapi biodata yuk...", "warning", 5000)
                setRedirect("/update-profile")
            }
        }
        else {
            setInfaqPopUp(!infaqPopUp)
        }
    }

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "share-btn":
                setSharePopUp(!sharePopUp)
                break
            case "infaq-btn":
                _onInfaqPress()
                break
            case "on-infaq":
                _onButtonInfaqPress(data)
                break
            default:
                console.log("Belum ada aksi")
                break
        }
    }

    return (
        <React.Fragment>
            <Seo
                is_article={true}
                title={institutions?.title ?? null}
                image_uri={institutions?.thumbnail}
                desc={truncateText(htmlToString(institutions?.content_desc ?? ''), 155, '...')}
                recent_link={window.location.href}
            />
            <PopUp title="Share" showPopUp={sharePopUp} onClose={() => { _action("share-btn") }}>
                <ShareContent
                    headerTitle={`YUK BERDONASI BERSAMA ${institutions?.title.toUpperCase()}`}
                    url={window.location.href}
                    title={`Kamu yang Berhati Baik! `}
                    quote={`Yuk Ikut Donasi Peduli Bersama Orang2 Baik Dari ${institutions?.title} `}
                    hashtag="#ringanin "
                    contentType="institution"
                    onClose={() => { _action("share-btn") }}
                    referral={institutions?.ref_code}
                    login={userInfo}
                />
            </PopUp>
            <PopUp title="Infak" showPopUp={infaqPopUp} onClose={() => { _action("infaq-btn") }}>
                <InfaqContent
                    headerTitle={institutions?.title}
                    data={paymentMethod?.models}
                    dataContent={{ type: 'institution', contentUniqueCode: institutions?.url_seo, refCode: refCode }}
                    actionCallBack={(data) => { _action("on-infaq", data) }}
                    withDataUser={userInfo ? false : true} //kalo data user ngga ada, munculkan form
                    disableBtn={!enableInfaq}
                />
            </PopUp>
            <InstitutionDetailHeader data={institutions} />
            <InstitutionDetailElement
                enableInfaq={enableInfaq}
                data={institutions}
                actionCallback={_action}
            />
            {/* jangan di hapus */}
            <Loaded data={institutions} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment >
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export const InstitutionDetail = connect(mapStateToProps, mapDispatchToProps)(InstitutionDetailRender);

