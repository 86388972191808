import React, { ReactElement } from 'react';
import {
    useHistory,
    Link
} from "react-router-dom";
import { TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'

const usePaths = (history: any) => {
    const [paths, setPaths] = React.useState([]);

    React.useEffect(() => {
        const listen = (location: any, action: any) => {
            const actions: any = {
                PUSH: () => [...paths, location.pathname],
                POP: () => [...paths.slice(0, paths.length - 1)],
                REPLACE: () => [...paths.slice(0, paths.length - 1), location.pathname]
            };

            const getPaths = actions[action];
            if (getPaths) {
                setPaths(getPaths());
            }
        };

        const unregister = history.listen(listen);
        return () => unregister();
    }, [history, paths]);

    return paths;
}

export const HeaderRender = (props: any): ReactElement => {
    const { checkFooter }: { checkFooter: boolean } = props;
    const history = useHistory();
    const paths = usePaths(history);

    const _goBack = () => {
        if (paths.length) {
            history.goBack()
        }
        else {
            history.push("/")
        }
    }

    return (
        <>
            <div className="header header-auto-show header-fixed" style={{ backgroundColor: '#459D71', textAlign: 'center' }}>
                <div style={{ maxWidth: 480, margin: 'auto' }}>
                    {
                        !checkFooter && (
                            <div style={{ alignItems: 'flex-start', position: 'absolute', marginTop: 12, marginLeft: 16 }}>
                                <TouchableOpacity>
                                    <button onClick={_goBack} style={{ backgroundColor: 'transparent', border: 0, color: '#fff' }}><i className="fa fa-arrow-left"></i> {paths.length ? "kembali" : "beranda"}</button>
                                </TouchableOpacity>
                            </div>
                        )
                    }
                    <Link to="/">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/logo/logo-tiny.png`} style={{ width: 120, marginTop: 4 }} />
                    </Link>
                </div>
            </div>

            <div className="page-title page-title-fixed">
                <div style={{ maxWidth: 480, margin: 'auto' }}>
                    <div style={{ margin: 'auto' }}>
                        <Link to="/">
                            <img className="img-fluid logo" src={`${process.env.PUBLIC_URL}/assets/logo/ringanin.png`} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="page-title-clear" style={{ marginBottom: 28 }}></div>
        </>
    );
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        checkFooter: state.footerDetection.footer
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {}
}
export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderRender);