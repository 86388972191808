import * as IFace from './footerDetection.interface.reducer'
import * as TYPE from '../type-redux/index'

const footerDetection: IFace.FooterDetection = {
    footer: false,
}

export const FooterDetection = (state = footerDetection, action: any): any => {
    switch (action.type) {
        case TYPE.FOOTER_DETECTION:
            return {
                ...state,
                footer: action.payload,
            }
            break;
        default:
            break;
    }
    return state;
}