import { EventEmitter } from 'events'
import dispatcher from '../utils/flux/index'
import { GraphQL } from '../services/index';
import {
    PINNED_WORSHIP_PLACE_GET_DATA,
    WORSHIP_PLACE_GET_DATA,
    WORSHIP_PLACE_GET_DATA_DETAIL
} from '../actions/index'
import * as IFace from './worshipPlace.interface.store'

class WorshipPlace extends EventEmitter implements IFace.RootObject {
    worshipPlaces: IFace.WorshipPlaces | null
    pinnedWorshipPlaces: IFace.PinnedWorshipPlaces | null
    worshipPlaceDetail: IFace.ModelWorshipPlaces | null
    constructor() {
        super();
        this.worshipPlaces = null;
        this.pinnedWorshipPlaces = null;
        this.worshipPlaceDetail = null
    }

    getWorshipPlaceQuery = async (currentPage: number = 1): Promise<any> => {
        let query: string
        query = `{
                    worshipPlaces(limit:10, currentPage:${currentPage}, filters:{sort:"distance ASC"}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            desc,
                            address,
                            thumbnail,
                            donate,
                            latitude,
                            longitude
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getWorshipPlaceFromServer = async (currentPage?: number): Promise<void> => {
        let result: any;
        result = await this.getWorshipPlaceQuery(currentPage);
        let { status, data }: { status: boolean, data: IFace.WorshipPlace } = result;
        if (result && status) {
            let tmpData: IFace.WorshipPlaces = data.worshipPlaces
            if (this.worshipPlaces) {
                tmpData.models = [...this.worshipPlaces.models, ...tmpData.models]
            }
            this.isSetWorshipPlace(tmpData)
        }
        this.emit(WORSHIP_PLACE_GET_DATA, { status })
    }

    isSetWorshipPlace = (data: IFace.WorshipPlaces): void => {
        this.worshipPlaces = data
    }

    isGetWorshipPlace = (): IFace.WorshipPlaces | null => {
        return this.worshipPlaces
    }

    getPinnedWorshipPlaceQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    pinnedWorshipPlaces:worshipPlaces(limit:10, filters:{pinned:1}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            desc,
                            address,
                            thumbnail,
                            donate,
                            latitude,
                            longitude
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getPinnedWorshipPlaceFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getPinnedWorshipPlaceQuery();
        let { status, data }: { status: boolean, data: IFace.PinnedWorshipPlace } = result;
        if (result && status) {
            let tmpData: IFace.PinnedWorshipPlaces = data.pinnedWorshipPlaces
            this.isSetPinnedWorshipPlace(tmpData)
        }
        this.emit(PINNED_WORSHIP_PLACE_GET_DATA, { status })
    }

    isSetPinnedWorshipPlace = (data: IFace.WorshipPlaces): void => {
        this.pinnedWorshipPlaces = data
    }

    isGetPinnedWorshipPlace = (): IFace.WorshipPlaces | null => {
        return this.pinnedWorshipPlaces
    }

    getWorshipPlaceDetailQuery = async (url: string): Promise<any> => {
        let query: string
        query = `{
                    worshipPlace(url:"${url}"){
                        url_seo,
                        title,
                        donate,
                        thumbnail,
                        telp,
                        email,
                        address,
                        desc,
                        content_title,
                        content_desc,
                        latitude,
                        longitude,
                        ref_code
                    }
                }`;
        return await GraphQL(query)
    }

    getWorshipPlaceDetailFromServer = async (url: string): Promise<void> => {
        let result: any;
        result = await this.getWorshipPlaceDetailQuery(url);
        let { status, data }: { status: boolean, data: IFace.WorshipPlaceDetail } = result;
        if (result && status) {
            this.isSetWorshipPlaceDetail(data.worshipPlace)
        }
        this.emit(WORSHIP_PLACE_GET_DATA_DETAIL, { status })
    }

    isSetWorshipPlaceDetail = (data: IFace.ModelWorshipPlaces): void => {
        this.worshipPlaceDetail = data
    }

    isGetWorshipPlaceDetail = (): IFace.ModelWorshipPlaces | null => {
        return this.worshipPlaceDetail
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case WORSHIP_PLACE_GET_DATA:
                this.getWorshipPlaceFromServer(action.payload)
                break
            case PINNED_WORSHIP_PLACE_GET_DATA:
                this.getPinnedWorshipPlaceFromServer()
                break
            case WORSHIP_PLACE_GET_DATA_DETAIL:
                this.getWorshipPlaceDetailFromServer(action.payload)
                break
        }
    }
}

const worshipPlaceStore = new WorshipPlace();
dispatcher.register(worshipPlaceStore.handleActions.bind(worshipPlaceStore))
export default worshipPlaceStore;