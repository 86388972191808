import dispatcher from '../utils/flux/index'
import { ARTICLE_GET_DATA, ARTICLE_GET_DATA_DETAIL } from './index'

export const getDataArticle = (currentPage?: number): void => {
    dispatcher.dispatch({
        type: ARTICLE_GET_DATA,
        payload: currentPage
    })
}

export const getDataArticleDetail = (url?: string): void => {
    dispatcher.dispatch({
        type: ARTICLE_GET_DATA_DETAIL,
        payload: url
    })
}

