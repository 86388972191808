import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { TouchableOpacity } from 'react-native'
import * as REDUXTYPES from '../../reduxs/type-redux/index'
import { Redirect, Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import { myDonateGetData, MY_DONATE_GET_DATA } from '../../actions/index'
import donateStore from '../../stores/donate.store'
import * as IDonate from '../../stores/donate.interface.store'
import { notify } from 'react-notify-toast'
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import LoadMore from './../../components/loadmores/index'
import prettyNumber from '../../utils/pretty-number'
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import Loaded from '../../components/loaded/index'

const ComponentEnd = (): ReactElement => {
    return (
        <tr><th colSpan={3}>semua data sudah ditampilkan</th></tr>
    )
}

const DataEnd = (props: any): ReactElement => (
    <LoadMore data={props.data} componentEnd={<ComponentEnd />} >
        <tr>
            <th colSpan={3}>memuat...</th>
        </tr>
        <div className="divider mb-3"></div>
    </LoadMore>
)

const DonateData = (props: any): ReactElement => {
    let { data, dataEnd }: { data: IDonate.Donates, dataEnd: string } = props
    if (data) {
        if (data.models.length) {
            return (
                <React.Fragment>
                    <Seo
                        title={`LAPORAN DONASI - ${process.env.REACT_APP_NAME}`}
                        recent_link={window.location.href}
                    />
                    {
                        data.models.map((val, ind) => {
                            return (
                                <tr>
                                    <td>{moment.unix(val.created_at).format("DD/MM/YYYY")}</td>
                                    <td>{prettyNumber(val.amount_origin, false, 1000000, 0, "0")} IDR</td>
                                    <td className="align-text-top">{val.validated_at && val.status ? "sukses" : (val.status == -1 || val.created_at >= moment().subtract(3, 'days').unix() ? "dibatalkan" : "pending")}</td>
                                </tr>
                            )
                        })
                    }
                    <DataEnd data={dataEnd} />
                </React.Fragment>
            )
        }
        return (<tr><td colSpan={3}>belum ada riwayat donasi</td></tr>)
    }
    return (
        <React.Fragment>
            <tr>
                <td>memuat</td>
                <td>memuat</td>
                <td>memuat</td>
            </tr>
        </React.Fragment>
    )

}

export const DonateReportRender = (props: any): ReactElement => {

    let isFinished: boolean = false
    let isTotalData: number
    let isGotData: number
    let currentPage: number
    let isBegin: number
    let isEnd: number


    const { setAuth }: { setAuth: (payload: null) => void } = props

    const [redirect, setRedirect] = React.useState<string>()
    const [begin, setBegin] = React.useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
    const [end, setEnd] = React.useState<Date>(new Date())
    const [donateLists, setDonateLists] = React.useState<IDonate.Donates | null>()

    const [dataEnd, setDataEnd] = React.useState<string>("")
    const [hasGotData, setHasGotData] = React.useState<boolean>(false)


    const [disabled, setDisabled] = React.useState<boolean>(false)
    const [, forceUpdate] = React.useState<number>();
    const simpleValidator = React.useRef(new SimpleReactValidator())

    let beginValidator = simpleValidator.current.message('begin', begin, 'required');
    let endValidator = simpleValidator.current.message('end', end, 'required');

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "get-data":
                _getData()
                break
            case "set-begin":
                _setDataBegin(data)
                break
            case "set-end":
                _setDataEnd(data)
                break
        }
    }

    const _getData = () => {
        setDonateLists(null)
        setDisabled(true)
        forceUpdate(0)
        if (simpleValidator.current.allValid()) {
            isBegin = moment(begin).unix()
            isEnd = moment(end).unix()
            myDonateGetData({ begin: isBegin, end: isEnd })
        }
        else {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        }
    }

    const _onGetData = () => {
        let result = donateStore.isGetMyDonate()
        isTotalData = result?.total ?? 0
        isGotData = result?.models.length ?? 0
        currentPage = result?.currentPage ?? 1

        setDonateLists(result)
        setDataEnd("default")
        isFinished = true
        setHasGotData(true)
        setDisabled(false)
    }

    const __loadMore = async (): Promise<void> => {
        isFinished = false
        myDonateGetData({ currentPage: currentPage + 1, begin: isBegin, end: isEnd })

    }

    const _onScroll = (): void => {
        if ((isFinished) && ((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
            if (isGotData < isTotalData) {
                setDataEnd("loadmore")
                __loadMore()
            }
            else {
                setDataEnd("end")
            }
        }
    }

    const _setDataBegin = (date: Date) => {
        setBegin(date)
        isBegin = moment(begin).unix()
    }

    const _setDataEnd = (date: Date) => {
        setEnd(date)
        isEnd = moment(date).unix()
    }

    React.useEffect(() => {
        donateStore._addListener(MY_DONATE_GET_DATA, _onGetData)
        window.addEventListener("scroll", _onScroll)
        isBegin = moment(begin).unix()
        isEnd = moment(end).unix()
        myDonateGetData({ begin: isBegin, end: isEnd })
        return () => {
            donateStore._removeListener(MY_DONATE_GET_DATA, _onGetData)
            window.removeEventListener("scroll", _onScroll)
        }
    }, [])

    return (
        <React.Fragment>
            <div className="card card-style rounded-m pb-1">
                <div className="content">
                    <h4 className="color-highlight">Filter Tanggal</h4>
                    <div className="divider mt-2 mb-2"></div>

                    <div className="col-12 p-0 mb-n4">
                        <div className="row">
                            <div className="col-6 input-style input-style-2 input-required mt-2">
                                <DatePicker
                                    selected={begin}
                                    onChange={(date: any) => _action("set-begin", date)}
                                    maxDate={new Date()}
                                />
                                <p style={{ color: 'red' }} className={beginValidator ? "mb-n2" : "mb-1"}>{beginValidator}</p>
                            </div>

                            <div className="col-6 input-style input-style-2 input-required mt-2">
                                <DatePicker
                                    selected={end}
                                    onChange={(date: any) => _action("set-end", date)}
                                    maxDate={new Date()}
                                />
                                <p style={{ color: 'red' }} className={endValidator ? "mb-n2" : "mb-1"}>{endValidator}</p>
                            </div>
                        </div>
                    </div>

                    <TouchableOpacity onPress={() => { _action("get-data") }}>
                        <button disabled={disabled} className="btn gradient-blue font-13 font-600 rounded-sm">Lihat Laporan</button>
                    </TouchableOpacity>
                    <h4 className="color-highlight mt-3 mb-2">Laporan Donasi</h4>
                    <table className="table table-sm">
                        <tr>
                            <td>Mulai</td>
                            <td>:</td>
                            <td>{hasGotData ? (moment(begin).format("DD/MM/YYYY")) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Sampai</td>
                            <td>:</td>
                            <td>{hasGotData ? (moment(end).format("DD/MM/YYYY")) : "-"}</td>
                        </tr>
                        <tr>
                            <td>Total Donasi Sukses</td>
                            <td>:</td>
                            <td><strong>{prettyNumber(donateLists?.successDonate, false, 1000000, 0, "0")} IDR</strong></td>
                        </tr>
                    </table>

                    <div className="divider mt-2 mb-2"></div>
                    <table className="table table-striped text-center rounded-sm" style={{ overflow: 'hidden' }}>
                        <thead>
                            <tr className="bg-blue-dark">
                                <th scope="col" className="color-white">Tanggal</th>
                                <th scope="col" className="color-white">Nominal</th>
                                <th scope="col" className="color-white">Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            <DonateData data={donateLists} dataEnd={dataEnd} />
                        </tbody>
                    </table>
                </div>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        isLatLong: state.latLong
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setAuth: (payload: null) => dispatch({ type: REDUXTYPES.AUTH_LOGIN, payload }),
    }
}
export const DonateReport = connect(mapStateToProps, mapDispatchToProps)(DonateReportRender);

export default DonateReport
