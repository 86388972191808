import React, { ReactElement, useRef } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Link, Redirect } from 'react-router-dom'
import institutionStore from './../../stores/institution.store'
import sliderStore from './../../stores/slider.store'
import worshipPlaceStore from '../../stores/worshipPlace.store'
import donateStore from '../../stores/donate.store'
import programStore from '../../stores/program.store'
import articleStore from '../../stores/article.store'
import paymentStore from '../../stores/payment.store'
import * as IFaceWorshipPlace from '../../stores/worshipPlace.interface.store'
import { notify } from 'react-notify-toast';
import {
    getDataPinnedWorshipPlace,
    getDataSlider,
    getDataProgram,
    getDataProgramDefault,
    getDataTopInstitution,
    createPayment,
    getDataPaymentMethod,
    getDataArticle,
    getTotalDonate,
    PAYMENT_CREATE_DATA,
    PAYMENT_METHOD_GET_DATA,
    PINNED_WORSHIP_PLACE_GET_DATA,
    PROGRAM_GET_DATA,
    SLIDER_GET_DATA,
    SLIDER_GET_DATA_DETAIL,
    TOP_INSTITUTION_GET_DATA,
    PROGRAM_GET_DATA_DEFAULT,
    ARTICLE_GET_DATA,
    TOTAL_DONATE_GET_DATA
} from './../../actions/index'
import moment from 'moment'
import { List1Loader, CarouselLoader, ContentTumbnailLoader } from './../../components/loaders/index'
import { FlatList, TouchableOpacity, View } from 'react-native'
import * as IFaceInstitution from './../../stores/institution.interface.store'
import * as IFaceSlider from './../../stores/slider.interface.store'
import * as IFaceProgram from '../../stores/program.interface.store'
import NoData from './../../components/nodatas/index'
import prettyNumber from './../../utils/pretty-number/index'
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import truncateText from './../../utils/truncate/index'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { connect } from 'react-redux'
import PopUp from './../../components/popups/index'
import InfaqContent from './../../components/dontates/index'
import * as IFacePayment from './../../stores/payment.interface.store'
import * as IFaceArticle from './../../stores/article.interface.store'
import { setCookie, readCookie } from './../../utils/cookie'
import * as IFaceAuth from './../../stores/auth.interface.store'
import socket from 'socket.io-client';
import parse from 'html-react-parser';
import {
    SliderElement,
    LastDonate,
    DataEnd
} from './child.home'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loaded from '../../components/loaded/index'


const IsDonate = (props: any): ReactElement => {
    let { disabled, onButtonPress }: { disabled: boolean, onButtonPress: () => void } = props;
    return (
        <div className="card card-style card-responsive-parent card-auto card-margin">
            <div className="content">
                <p className="font-600 mb-0 color-highlight">Donasi berapapun kamu mau</p>
                <div className="divider mt-2 mb-2"></div>
                <TouchableOpacity>
                    <button onClick={() => onButtonPress()} disabled={disabled} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-1 rounded-sm" style={{ width: '100%' }}>
                        Donasi Sekarang
                    </button>
                </TouchableOpacity>
            </div>
        </div>
    )
}

const TopInstitutionElement = (props: any): ReactElement => {
    let { data }: { data: IFaceInstitution.TopInstitutions } = props
    if (data) {
        if (data.models.length) {
            return (
                <div className="card card-style card-responsive-parent card-auto card-margin">
                    <div className="content">
                        <FlatList
                            data={data.models}
                            renderItem={({ item }: { item: IFaceInstitution.ModelTopInstitutions }) => {
                                return (<>
                                    <div className="d-flex mb-3">
                                        <div className="w-100 pr-3 w-100 pt-1">
                                            <TouchableOpacity>
                                                <Link to={`/institution/${item.url_seo}`}>
                                                    <h3 className="institution-title font-700">{truncateText(item.title, 47)}</h3>
                                                </Link>
                                            </TouchableOpacity>
                                            <p style={{ marginBottom: 4 }}>{truncateText((item.tagline ?? ""), 70)}</p>
                                            <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                        </div>
                                        <div className="mr-auto">
                                            <img src={item.logo ?? "/theme/images/pictures/33s.jpg"} className="logo-institution rounded-s" />
                                        </div>
                                    </div>

                                    <div className="divider mb-3"></div>
                                </>)
                            }}
                            keyExtractor={(item: any) => item.id}
                        />
                    </div>
                </div>
            )
        }
        return (
            <NoData />
        )
    }
    return (<List1Loader />)
}

const PinnedWorshipPlaceElement = (props: any): ReactElement => {
    let { data }: { data: IFaceWorshipPlace.PinnedWorshipPlaces } = props
    if (data) {
        if (data.models.length) {
            return (
                <OwlCarousel
                    className="owl-theme"
                    loop
                    nav
                    autoWidth
                >
                    {
                        data.models.map((item, index) => {
                            return (
                                <div className="card card-style card-responsive-parent is-carousel card-auto card-margin" style={{ marginBottom: 18 }}>
                                    <div className="card card-responsive card-carousel rounded-cstm">
                                        <img
                                            key={item.url_seo}
                                            src={item.thumbnail}
                                            alt={item.title}
                                            className="img-fluid card-responsive rounded-cstm"
                                        />
                                    </div>
                                    <div className="content mt-3">
                                        <p className="font-600 mb-0 color-highlight">Masjid</p>
                                        <h3 className="font-700">{truncateText(item.title, 35)}</h3>
                                        <p className="mb-0"><i className="fa fa-map-marker" style={{ color: '#4ea57a' }}></i> &nbsp;{truncateText((item.address) ?? "", 39)}</p>
                                        <div className="divider mb-2"></div>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                            <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                            <TouchableOpacity>
                                                <Link to={`/worship-place/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                            </TouchableOpacity>
                                        </View>
                                    </div>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            )
        }
        return (<NoData />)
    }
    return (<CarouselLoader />)
}

const ProgramElement = (props: any): ReactElement => {
    let { data }: { data: IFaceProgram.Programs } = props
    if (data) {
        if (data.models.length) {
            return (
                <FlatList
                    data={data.models}
                    onScrollEndDrag={() => console.log("end")}

                    renderItem={({ item }: { item: IFaceProgram.ModelPrograms }) => {
                        return (
                            <div className="card card-style card-responsive-parent card-auto card-margin">
                                <div className="card card-responsive rounded-cstm">
                                    <img src={item.thumbnail} className="card-responsive rounded-cstm" />
                                </div>
                                <div className="content mt-3">
                                    <p className="font-600 mb-0 color-highlight">Program</p>
                                    <h3 className="font-700">{truncateText(item.title, 40)}</h3>
                                    <p className="mb-1">{parse(truncateText((item.desc) ?? "", 89))}</p>
                                    <div className="divider mb-2"></div>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                        <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                        <TouchableOpacity>
                                            <Link to={`/program/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                        </TouchableOpacity>
                                    </View>
                                </div>
                            </div>
                        )
                    }}
                    keyExtractor={(item: any) => item.id}
                />
            )
        }
        return (<NoData />)
    }
    return (<ContentTumbnailLoader />)
}

const ArticleElement = (props: any): ReactElement => {
    let { data }: { data: IFaceArticle.Articles } = props
    if (data) {
        if (data.models.length) {
            return (
                <FlatList
                    data={data.models}
                    onScrollEndDrag={() => console.log("end")}

                    renderItem={({ item }: { item: IFaceArticle.ModelArticles }) => {
                        let time = parseInt(item.created_at ?? "0")
                        return (
                            <div className="card card-style card-responsive-parent card-auto card-margin">
                                <div className="card card-responsive rounded-cstm" style={{ backgroundColor: '#e5e5e5' }}>
                                    <LazyLoadImage
                                        key={item.url_seo}
                                        src={item.thumbnail}
                                        alt={item.title}
                                        effect="blur"
                                        className="img-fluid card-responsive rounded-cstm"
                                    />
                                </div>
                                <div className="content mt-3">
                                    <p className="font-600 mb-0 color-highlight">Article</p>
                                    <h3 className="font-700">{truncateText(item.title, 40)}</h3>
                                    <i className="fa fa-clock"></i> {moment.unix(time).format("DD/MM/YYYY, HH:MM:SS")}
                                    <p className="mb-1">{parse(truncateText((item.content) ?? "", 150))}</p>
                                    <div className="divider mb-2"></div>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                        <TouchableOpacity>
                                            <Link to={`/article/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                        </TouchableOpacity>
                                    </View>
                                </div>
                            </div>
                        )
                    }}
                    keyExtractor={(item: any) => item.id}
                />
            )
        }
        return (<NoData />)
    }
    return (<ContentTumbnailLoader />)
}

type IHomeRender = {
    auth: IFaceAuth.DataLogin
}

export const HomeRender: React.FC<IHomeRender> = (props): ReactElement => {
    let { auth } = props;

    let isFinished: boolean = false
    let isTotalData: number
    let isGotData: number
    let currentPage: number

    const [sliders, setSliders] = React.useState<IFaceSlider.Sliders | null | undefined>()
    const [topInstitutions, setTopInstitutions] = React.useState<IFaceInstitution.TopInstitutions | null | undefined>()
    const [pinnedWorships, setPinnedWorships] = React.useState<IFaceWorshipPlace.WorshipPlaces | null | undefined>()
    const [program, setPrograms] = React.useState<IFaceProgram.Programs | null | undefined>()
    const [article, setArticles] = React.useState<IFaceArticle.Articles | null | undefined>()
    const [programDefault, setProgramDefault] = React.useState<IFaceProgram.ModelPrograms | undefined | null>()
    const [dataEnd, setDataEnd] = React.useState<string>("")
    const [paymentMethod, setPaymentMethod] = React.useState<IFacePayment.Payments | null>(null)
    const [enableInfaq, setEnableInfaq] = React.useState<boolean>(false)
    const [redirect, setRedirect] = React.useState<string>();
    const [infaqPopUp, setInfaqPopUp] = React.useState<boolean>(false)
    const [userInfo, setUserInfo] = React.useState<IFaceAuth.ILogin>()
    const [refCode, setRefCode] = React.useState<string>();
    const [totalDonate, setTotalDonate] = React.useState<number | null>(0);


    // React.useEffect(() => {
    //     const io = socket.io('http://localhost:3001');
    //     io.on('donate', function (msg: any) {
    //         alert("hehe")
    //     });
    // })

    const _onGetDataSlider = (): void => {
        let result = sliderStore.isGetSlider()
        setSliders(result)
    }

    const _onGetDataTopInstitution = (): void => {
        let result = institutionStore.isGetTopInstitution()
        setTopInstitutions(result)
    }

    const _onGetDataPinnedWorship = (): void => {
        let result = worshipPlaceStore.isGetPinnedWorshipPlace()
        setPinnedWorships(result)
    }

    const _onGetDataProgram = (): void => {
        let result = programStore.isGetProgram()
        // isTotalData = result?.total ?? 0
        // isGotData = result?.models.length ?? 0
        // currentPage = result?.currentPage ?? 1
        setPrograms(result)
        // setDataEnd("default")
        isFinished = true
    }

    const _onGetDataArticle = (): void => {
        let result = articleStore.isGetArticle()
        isTotalData = result?.total ?? 0
        isGotData = result?.models.length ?? 0
        currentPage = result?.currentPage ?? 1
        setArticles(result)
        setDataEnd("default")
        isFinished = true
    }

    const _onGetDataProgramDefault = () => {
        let result = programStore.isGetProgramDefault();
        setProgramDefault(result)
    }

    const _onGetDataPaymentMethod = (status: boolean) => {
        let result = paymentStore.isGetPayment()
        setPaymentMethod(result)
        setEnableInfaq(status)
    }

    const _onGetTotalDonate = (status: boolean) => {
        let result = donateStore.isGetTotalDonate()
        setTotalDonate(result)
    }

    const _onPaymentGetData = () => {
        setEnableInfaq(true)
        let result = paymentStore.isGetResponseCreatePayment()
        if (result && result.status) {
            notify.show("Pembayaran sedang diproses", "success", 3000)
            if (result.redirect == "detail") {
                setRedirect(`/payment/detail/${result.internal_payment_code}`);
            }
            else if (result.redirect == "done") {
                setRedirect(`/payment/status/${result.internal_payment_code}`);
            }
            else {
                window.location.reload()
            }
        }
        else {
            notify.show("Sistem sibuk", "error", 3000)
        }
    }

    React.useEffect(() => {
        _setUserInfo(auth)
    })

    React.useEffect(() => {
        getDataPaymentMethod()
        institutionStore._addListener(TOP_INSTITUTION_GET_DATA, _onGetDataTopInstitution)
        programStore._addListener(PROGRAM_GET_DATA, _onGetDataProgram)
        articleStore._addListener(ARTICLE_GET_DATA, _onGetDataArticle)
        donateStore._addListener(TOTAL_DONATE_GET_DATA, _onGetTotalDonate)
        programStore._addListener(PROGRAM_GET_DATA_DEFAULT, _onGetDataProgramDefault)
        worshipPlaceStore._addListener(PINNED_WORSHIP_PLACE_GET_DATA, _onGetDataPinnedWorship)
        sliderStore._addListener(SLIDER_GET_DATA, _onGetDataSlider)
        paymentStore._addListener(PAYMENT_METHOD_GET_DATA, _onGetDataPaymentMethod)
        paymentStore._addListener(PAYMENT_CREATE_DATA, _onPaymentGetData)
        window.addEventListener("scroll", _onScroll)

        let programDefault = programStore.isGetProgramDefault()

        if (!programDefault) {
            getDataProgramDefault()
        }
        else {
            _onGetDataProgramDefault()
        }

        let pinnedWorship = worshipPlaceStore.isGetPinnedWorshipPlace()
        if (!pinnedWorship) {
            getDataPinnedWorshipPlace()
        }
        else {
            _onGetDataPinnedWorship()
        }

        let topInstitution = institutionStore.isGetInstitution()
        if (!topInstitution) {
            getDataTopInstitution()
        }
        else {
            _onGetDataTopInstitution()
        }

        getDataSlider("home")

        let program = programStore.isGetProgram()
        if (!program) {
            getDataProgram()
        }
        else {
            _onGetDataProgram()
        }

        let article = articleStore.isGetArticle()
        if (!article) {
            getDataArticle()
        }
        else {
            _onGetDataArticle()
        }

        getTotalDonate()

        return () => {
            programStore._removeListener(PROGRAM_GET_DATA, _onGetDataProgram)
            donateStore._removeListener(TOTAL_DONATE_GET_DATA, _onGetTotalDonate)
            articleStore._removeListener(ARTICLE_GET_DATA, _onGetDataArticle)
            programStore._removeListener(PROGRAM_GET_DATA_DEFAULT, _onGetDataProgramDefault)
            institutionStore._removeListener(TOP_INSTITUTION_GET_DATA, _onGetDataTopInstitution)
            worshipPlaceStore._removeListener(PINNED_WORSHIP_PLACE_GET_DATA, _onGetDataPinnedWorship)
            sliderStore._removeListener(SLIDER_GET_DATA_DETAIL, _onGetDataSlider)
            paymentStore._removeListener(PAYMENT_METHOD_GET_DATA, _onGetDataPaymentMethod)
            paymentStore._removeListener(PAYMENT_CREATE_DATA, _onPaymentGetData)
            window.removeEventListener("scroll", _onScroll)
        }
    }, [])

    const __loadMore = async (): Promise<void> => {
        isFinished = false
        getDataArticle(currentPage + 1)

    }

    const _onScroll = (): void => {
        if ((isFinished) && ((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
            if (isGotData < isTotalData) {
                setDataEnd("loadmore")
                __loadMore()
            }
            else {
                setDataEnd("end")
            }
        }
    }

    const _onInfaqPress = () => {
        if (userInfo) {
            if (userInfo.email && userInfo.full_name && userInfo.phone) {
                setInfaqPopUp(!infaqPopUp)
            }
            else {
                setCookie("after-save", "/", 1)
                notify.show("Lengkapi biodata yuk...", "warning", 5000)
                setRedirect("/update-profile")
            }
        }
        else {
            setInfaqPopUp(!infaqPopUp)
        }
    }

    const _onButtonInfaqPress = (data: IFacePayment.DataInfaq): void => {
        setEnableInfaq(false)
        let msg = "Kotak Infak";
        data = { ...data, message: msg, paymentMessage: msg, description: msg };
        if (userInfo) {
            let phone = userInfo.phone;
            let alias = userInfo.full_name;
            let email = userInfo.email;
            data = {
                ...data,
                customerMobilePhone: (data.customerMobilePhone && data.customerMobilePhone != phone ? data.customerMobilePhone : phone),
                email: email,
                alias: alias,
            }
        }
        createPayment(data)
    }

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "infaq-btn":
                _onInfaqPress()
                break
            case "on-infaq":
                _onButtonInfaqPress(data)
                break
            default:
                console.log("Belum ada aksi")
                break
        }
    }

    const _setUserInfo = (data: IFaceAuth.DataLogin): void => {
        setUserInfo(data.login)
    }

    return (
        <React.Fragment>
            <Seo
                title={`BERANDA - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <PopUp title="Infak" showPopUp={infaqPopUp} onClose={() => { _action("infaq-btn") }}>
                <InfaqContent
                    headerTitle={"Kotak Infak"}
                    data={paymentMethod?.models}
                    dataContent={{ type: 'program', contentUniqueCode: programDefault?.url_seo, refCode: refCode }}
                    actionCallBack={(data) => { _action("on-infaq", data) }}
                    withDataUser={userInfo ? false : true} //kalo data user ngga ada, munculkan form
                    disableBtn={!enableInfaq}
                />
            </PopUp>
            <SliderElement data={sliders} />
            <IsDonate
                disabled={programDefault && paymentMethod ? false : true}
                onButtonPress={() => { setInfaqPopUp(true) }}
            />
            <LastDonate totalDonate={totalDonate} />
            <h2 className="color-highlight">Bantu tempat ibadah ini yuk!</h2>
            <PinnedWorshipPlaceElement data={pinnedWorships} />
            <h2 className="color-highlight">Program donasi</h2>
            <ProgramElement data={program} />
            <h2 className="color-highlight">Artikel</h2>
            <ArticleElement data={article} />
            <DataEnd data={dataEnd} />
            {/* jangan di hapus */}
            <Loaded data={article} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        isLatLong: state.latLong,
        auth: state.auth
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {}
}
export const Home = connect(mapStateToProps, mapDispatchToProps)(HomeRender);

export default Home
