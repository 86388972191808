import { EventEmitter } from 'events'
import dispatcher from '../utils/flux/index'
import { GraphQL } from '../services/index';
import {
    ARTICLE_GET_DATA,
    ARTICLE_GET_DATA_DETAIL,
} from '../actions/index'
import * as IFace from './article.interface.store'

class Article extends EventEmitter implements IFace.RootObject {
    articles: IFace.Articles | null
    articleDetail: IFace.ModelArticles | null
    constructor() {
        super();
        this.articles = null;
        this.articleDetail = null;
    }

    getArticleQuery = async (currentPage: number = 1): Promise<any> => {
        let query: string
        query = `{
                    articles(limit:10, currentPage:${currentPage}, filters:{sort:"title ASC"}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            content,
                            thumbnail,
                            created_at
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getArticleFromServer = async (currentPage?: number): Promise<void> => {
        let result: any;
        result = await this.getArticleQuery(currentPage);
        let { status, data }: { status: boolean, data: IFace.Article } = result;
        if (result && status) {
            let tmpData: IFace.Articles = data.articles
            if (this.articles) {
                tmpData.models = [...this.articles.models, ...tmpData.models]
            }
            this.isSetArticle(tmpData)
        }
        this.emit(ARTICLE_GET_DATA, { status })
    }

    isSetArticle = (data: IFace.Articles): void => {
        this.articles = data
    }

    isGetArticle = (): IFace.Articles | null => {
        return this.articles
    }

    getArticleDetailQuery = async (url: string): Promise<any> => {
        let query: string
        query = `{
                    article(url:"${url}"){
                        url_seo,
                        title,
                        thumbnail,
                        content,
                        created_at
                    }
                }`;
        return await GraphQL(query)
    }

    getArticleDetailFromServer = async (url: string): Promise<void> => {
        let result: any;
        result = await this.getArticleDetailQuery(url);
        let { status, data }: { status: boolean, data: IFace.ArticleDetail } = result;
        if (result && status) {
            this.isSetArticleDetail(data.article)
        }
        this.emit(ARTICLE_GET_DATA_DETAIL, { status })
    }

    isSetArticleDetail = (data: IFace.ModelArticles): void => {
        this.articleDetail = data
    }

    isGetArticleDetail = (): IFace.ModelArticles | null => {
        return this.articleDetail
    }

    setArticleNull = (): void => {
        this.articles = null
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case ARTICLE_GET_DATA:
                this.getArticleFromServer(action.payload)
                break
            case ARTICLE_GET_DATA_DETAIL:
                this.getArticleDetailFromServer(action.payload)
                break
        }
    }
}

const articleStore = new Article();
dispatcher.register(articleStore.handleActions.bind(articleStore))
export default articleStore;