import React, { ReactElement, useRef } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Link } from 'react-router-dom'
import institutionStore from './../../stores/institution.store'
import sliderStore from './../../stores/slider.store'
import Seo from '../../components/seo/index'
import {
    getDataInstitution,
    getDataSlider,
    getDataTopInstitution,
    INSTITUTION_GET_DATA,
    SLIDER_GET_DATA,
    SLIDER_GET_DATA_DETAIL,
    TOP_INSTITUTION_GET_DATA
} from './../../actions/index'
import { SliderLoader, List1Loader, List2Loader } from './../../components/loaders/index'
import { FlatList, TouchableOpacity, View } from 'react-native'
import * as IFaceInstitution from './../../stores/institution.interface.store'
import * as IFaceSlider from './../../stores/slider.interface.store'
import NoData from './../../components/nodatas/index'
import prettyNumber from './../../utils/pretty-number/index'
import truncateText from './../../utils/truncate/index'
import LoadMore from './../../components/loadmores/index'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loaded from '../../components/loaded/index'

const SliderElement = (props: any): ReactElement => {
    let { data }: { data: IFaceSlider.Sliders } = props
    if (data) {
        if (data.models.length) {
            return (
                <div className="card card-style card-responsive-parent card-margin">
                    <Carousel showIndicators={false} showThumbs={false}>
                        {
                            data.models.map((val, data) => {
                                return (
                                    <div className="card card-responsive">
                                        <img src={val.image} className="img-fluid card-responsive rounded-cstm" />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
            )
        }
        return (<NoData />)
    }
    return (<SliderLoader />)
}

const TopInstitutionElement = (props: any): ReactElement => {
    let { data }: { data: IFaceInstitution.TopInstitutions } = props
    if (data) {
        if (data.models.length) {
            return (
                <div className="card card-style card-responsive-parent card-auto card-margin">
                    <div className="content">
                        <FlatList
                            data={data.models}
                            renderItem={({ item }: { item: IFaceInstitution.ModelTopInstitutions }) => {
                                return (<>
                                    <div className="d-flex mb-3">
                                        <div className="w-100 pr-3 w-100 pt-1">
                                            <TouchableOpacity>
                                                <Link to={`/institution/${item.url_seo}`}>
                                                    <h3 className="institution-title font-700">{truncateText(item.title, 47)}</h3>
                                                </Link>
                                            </TouchableOpacity>
                                            <p style={{ marginBottom: 4 }}>{truncateText((item.tagline ?? ""), 70)}</p>
                                            <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                        </div>
                                        <div className="mr-auto">
                                            <img src={item.logo ?? "/theme/images/pictures/33s.jpg"} className="logo-institution rounded-s" />
                                        </div>
                                    </div>

                                    <div className="divider mb-3"></div>
                                </>)
                            }}
                            keyExtractor={(item: any) => item.id}
                        />
                    </div>
                </div>
            )
        }
        return (
            <NoData />
        )
    }
    return (<List1Loader />)
}

const InstitutionElement = (props: any): ReactElement => {
    let { data }: { data: IFaceInstitution.Institutions } = props
    if (data) {
        if (data.models.length) {
            return (
                <FlatList
                    data={data.models}
                    onScrollEndDrag={() => console.log("end")}
                    renderItem={({ item }: { item: IFaceInstitution.ModelInstitutions }) => {
                        return (<>
                            <div className="card card-style card-responsive-parent card-auto card-margin">
                                <div className="content">
                                    <div className="d-flex">
                                        <img src={item.logo ?? "/theme/images/pictures/17s.jpg"} className="logo-institution rounded-s" />
                                        <div className="w-100 ml-2 pt-1">
                                            <h3 className="institution-title font-700">{truncateText(item.title, 47)}</h3>
                                            <p style={{ marginBottom: 4 }}>{truncateText((item.tagline ?? ""), 90)}</p>
                                            <div className="divider mb-2"></div>
                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                                <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                                <TouchableOpacity>
                                                    <Link to={`/institution/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">I N F A K</button></Link>
                                                </TouchableOpacity>
                                            </View>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    }}
                    keyExtractor={(item: any) => item.id}
                />
            )
        }
        return (<NoData />)
    }
    return (<List2Loader />)

}

const DataEnd = (props: any): ReactElement => (
    <LoadMore data={props.data}>
        <List2Loader />
        <div className="divider mb-3"></div>
    </LoadMore>
)

const SearchInstitution = (props: any): ReactElement => {
    const { onTyping }: { onTyping: (value: string) => void } = props;
    const [search, setSearch] = React.useState<string>("");
    const _onButtonSearchPress = () => {
        onTyping(search)
    }
    const _onTyping = (value: string) => {
        setSearch(value)
        onTyping(value)
    }
    return (
        <div className="card card-style card-responsive-parent card-auto card-margin">
            <div className="content">
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingBottom: -4 }}>
                    <View style={{ width: '70%', marginRight: 16 }}>
                        <div className="input-style input-style-2 input-required mt-2">
                            <input name="alias" value={search} onChange={(e) => { _onTyping(e.target.value) }} placeholder="Typing 3 huruf untuk mencari" className="form-control" type="name" />
                        </div>
                    </View>
                    <View style={{ flex: 1 }}>
                        <TouchableOpacity>
                            <button disabled={search.length < 3 ? true : false} onClick={_onButtonSearchPress} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-2 rounded-sm" style={{ width: '100%' }}>
                                Cari
                            </button>
                        </TouchableOpacity>
                    </View>
                </View>
            </div>
        </div>
    )
}

export const Institution = (): ReactElement => {

    let isFinished: boolean = false
    let isTotalData: number
    let isGotData: number
    let currentPage: number
    let isKeyWords: string

    const [sliders, setSliders] = React.useState<IFaceSlider.Sliders | null | undefined>()
    const [topInstitutions, setTopInstitutions] = React.useState<IFaceInstitution.TopInstitutions | null | undefined>()
    const [institutions, setInstitutions] = React.useState<IFaceInstitution.Institutions | null | undefined>()
    const [dataEnd, setDataEnd] = React.useState<string>("")
    const [keyWords, setKeyWords] = React.useState<string>("")

    const _onGetDataInstitution = (): void => {
        let result = institutionStore.isGetInstitution()

        console.log("RESULT", result)

        isTotalData = result?.total ?? 0
        isGotData = result?.models.length ?? 0
        currentPage = result?.currentPage ?? 1
        setInstitutions(result)
        setDataEnd("default")
        isFinished = true
    }

    const _onGetDataTopInstitution = (): void => {
        let result = institutionStore.isGetTopInstitution()
        setTopInstitutions(result)
    }

    const _onGetDataSlider = (): void => {
        let result = sliderStore.isGetSlider()
        setSliders(result)
    }

    React.useEffect(() => {
        institutionStore._addListener(INSTITUTION_GET_DATA, _onGetDataInstitution)
        institutionStore._addListener(TOP_INSTITUTION_GET_DATA, _onGetDataTopInstitution)
        sliderStore._addListener(SLIDER_GET_DATA, _onGetDataSlider)

        window.addEventListener("scroll", _onScroll)

        let institution = institutionStore.isGetInstitution()
        if (!institution) {
            getDataInstitution({})
        }
        else {
            _onGetDataInstitution()
        }

        let topInstitution = institutionStore.isGetInstitution()
        if (!topInstitution) {
            getDataTopInstitution()
        }
        else {
            _onGetDataTopInstitution()
        }

        getDataSlider("institution")

        return () => {
            institutionStore._removeListener(INSTITUTION_GET_DATA, _onGetDataInstitution)
            institutionStore._removeListener(TOP_INSTITUTION_GET_DATA, _onGetDataTopInstitution)
            sliderStore._removeListener(SLIDER_GET_DATA_DETAIL, _onGetDataSlider)
            window.removeEventListener("scroll", _onScroll)
        }
    }, [])

    const __loadMore = async (): Promise<void> => {
        isFinished = false
        getDataInstitution({ currentPage: currentPage + 1 })
    }

    const _onScroll = (): void => {
        if ((isFinished) && ((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
            if (isGotData < isTotalData) {
                setDataEnd("loadmore")
                __loadMore()
            }
            else {
                setDataEnd("end")
            }
        }
    }

    // const _onSearchTyping = (value: string) => {
    //     setKeyWords(value)
    //     getDataInstitution({ keyWords: value })
    // }

    return (
        <React.Fragment>
            <Seo
                title={`LIST INSTITUSI BAIK - ${process.env.REACT_APP_NAME}`}
                desc='Kumpulan institusi yang berkontribusi besar terhadap masyarakat dengan selalu berdonasi di platform Ringanin.id'
                recent_link={window.location.href}
            />
            <SliderElement data={sliders} />
            <h2 className="color-highlight">Infak terbanyak bulan ini</h2>
            <TopInstitutionElement data={topInstitutions} />
            <h2 className="color-highlight">Infak atas nama lembaga</h2>
            {/* <SearchInstitution
                onTyping={(value: string) => {
                    _onSearchTyping(value);
                }}
            /> */}
            <InstitutionElement data={institutions} />
            <DataEnd data={dataEnd} />
            {/* jangan di hapus */}
            <Loaded data={institutions} />
        </React.Fragment>
    )
}
