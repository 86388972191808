export const setLocalStorage = async (name: string, value: any): Promise<boolean> => {
    try {
        window.localStorage.setItem(name, value);
        return true;
    }
    catch (e) {
        return false;
    }
}

export const readLocalStorage = async (name: string): Promise<null | string> => {
    try {
        let result = window.localStorage.getItem(name);
        return result;
    }
    catch (e) {
        return null;
    }
}

export const removeLocalStorage = async (name: string): Promise<boolean> => {
    try {
        let result = window.localStorage.removeItem(name);
        return true;
    }
    catch (e) {
        return false;
    }
}