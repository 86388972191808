import { EventEmitter } from 'events'
import dispatcher from './../utils/flux/index'
import { GraphQL } from './../services/index';
import {
    INSTITUTION_GET_DATA,
    INSTITUTION_GET_DATA_DETAIL,
    TOP_INSTITUTION_GET_DATA
} from '../actions/index'
import * as IFace from './institution.interface.store'

class Institution extends EventEmitter implements IFace.RootObject {
    institutions: IFace.Institutions | null
    topInstitutions: IFace.TopInstitutions | null
    institutionDetail: IFace.ModelInstitutions | null
    constructor() {
        super();
        this.institutions = null;
        this.topInstitutions = null;
        this.institutionDetail = null
    }

    getInsitutionQuery = async (currentPage: number = 1, keyWords: string = ""): Promise<any> => {
        let query: string
        query = `{
                    institutions(limit:10, currentPage:${currentPage}, 
                        filters:{
                            sort:"title ASC",
                            title: "${keyWords}"
                        }){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            tagline,
                            logo,
                            donate
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getInstitutionFromServer = async (currentPage?: number, keyWords?: string): Promise<void> => {
        let result: any;
        result = await this.getInsitutionQuery(currentPage, keyWords);
        let { status, data }: { status: boolean, data: IFace.Institution } = result;
        if (result && status) {
            let tmpData: IFace.Institutions = data.institutions
            if (this.institutions && !keyWords) {
                tmpData.models = [...this.institutions.models, ...tmpData.models]
            }
            this.isSetInstitution(tmpData)
        }
        this.emit(INSTITUTION_GET_DATA, { status })
    }

    isSetInstitution = (data: IFace.Institutions): void => {
        this.institutions = data
    }

    isGetInstitution = (): IFace.Institutions | null => {
        return this.institutions
    }

    getTopInsitutionQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    topInstitutions:institutions(limit:5, filters:{sort:"donate DESC"}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            tagline,
                            logo,
                            donate
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getTopInstitutionFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getTopInsitutionQuery();
        let { status, data }: { status: boolean, data: IFace.TopInstitution } = result;
        if (result && status) {
            let tmpData: IFace.TopInstitutions = data.topInstitutions
            this.isSetTopInstitution(tmpData)
        }
        this.emit(TOP_INSTITUTION_GET_DATA, { status })
    }

    isSetTopInstitution = (data: IFace.Institutions): void => {
        this.topInstitutions = data
    }

    isGetTopInstitution = (): IFace.Institutions | null => {
        return this.topInstitutions
    }

    getInstitutionDetailQuery = async (url: string): Promise<any> => {
        let query: string
        query = `{
                    institution(url:"${url}"){
                        id,
                        url_seo,
                        title,
                        tagline,
                        logo,
                        donate,
                        thumbnail,
                        telp,
                        email,
                        address,
                        desc,
                        content_title,
                        content_desc,
                        ref_code
                    }
                }`;
        return await GraphQL(query)
    }

    getInstitutionDetailFromServer = async (url: string): Promise<void> => {
        let result: any;
        result = await this.getInstitutionDetailQuery(url);
        let { status, data }: { status: boolean, data: IFace.InstitutionDetail } = result;
        if (result && status) {
            this.isSetInstitutionDetail(data.institution)
        }
        this.emit(INSTITUTION_GET_DATA_DETAIL, { status })
    }

    isSetInstitutionDetail = (data: IFace.ModelInstitutions): void => {
        this.institutionDetail = data
    }

    isGetInstitutionDetail = (): IFace.ModelInstitutions | null => {
        return this.institutionDetail
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case INSTITUTION_GET_DATA:
                let { currentPage, keyWords }: { currentPage: number, keyWords?: string } = action.payload
                this.getInstitutionFromServer(currentPage, keyWords)
                break
            case TOP_INSTITUTION_GET_DATA:
                this.getTopInstitutionFromServer()
                break
            case INSTITUTION_GET_DATA_DETAIL:
                this.getInstitutionDetailFromServer(action.payload)
                break
        }
    }
}

const institutionStore = new Institution();
dispatcher.register(institutionStore.handleActions.bind(institutionStore))
export default institutionStore;