import { EventEmitter } from 'events'
import dispatcher from './../utils/flux/index'
import { GraphQL } from './../services/index';
import {
    PAYMENT_METHOD_GET_DATA,
    PAYMENT_CREATE_DATA,
    PAYMENT_GET_DATA_DETAIL
} from '../actions/index'
import * as IFace from './payment.interface.store'
import { type } from 'os';

class Payment extends EventEmitter implements IFace.RootObject {
    paymentMethods: IFace.Payments | null
    createPaymentResponse: IFace.IsDonate | null
    paymentDetail: IFace.PaymentDetail | null
    constructor() {
        super();
        this.paymentMethods = null
        this.createPaymentResponse = null
        this.paymentDetail = null
    }

    getPaymentQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    paymentMethods(filters:{status:1}){
                        limit,
                        total,
                        currentPage,
                        models{
                            id,
                            code,
                            name,
                            desc,
                            data,
                            logo,
                            min_amount
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getPaymentFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getPaymentQuery();
        let { status, data }: { status: boolean, data: IFace.Payment } = result;
        if (result && status) {
            let tmpData: IFace.Payments = data.paymentMethods
            if (this.paymentMethods) {
                tmpData.models = [...this.paymentMethods.models, ...tmpData.models]
            }
            this.isSetPayment(tmpData)
        }
        this.emit(PAYMENT_METHOD_GET_DATA, { status })
    }

    isSetPayment = (data: IFace.Payments): void => {
        this.paymentMethods = data
    }

    isGetPayment = (): IFace.Payments | null => {
        return this.paymentMethods
    }

    getPaymentDetailQuery = async (code: string): Promise<any> => {
        let query: string
        query = `{ 
                    donate(code:"${code}"){
                        internal_payment_code,
                        max_time,
                        amount_origin,
                        status,
                        value,
                        name,
                        msg,
                        payment_info_str
                    }
                }`;
        return await GraphQL(query)
    }

    getPaymentDetailFromServer = async (code: string): Promise<void> => {
        let result: any;
        result = await this.getPaymentDetailQuery(code);
        let { status, data }: { status: boolean, data: IFace.PaymentDetailResponse } = result;
        if (result && status) {
            let tmpData: IFace.PaymentDetail = data.donate
            this.isSetPaymentDetail(tmpData)
        }
        this.emit(PAYMENT_GET_DATA_DETAIL, { status })
    }

    isSetPaymentDetail = (data: IFace.PaymentDetail): void => {
        this.paymentDetail = data
    }

    isGetPaymentDetail = (): IFace.PaymentDetail | null => {
        return this.paymentDetail
    }

    paymentQuery = async (data: IFace.DataInfaq): Promise<any> => {
        let query: string
        query = `mutation{ 
            isDonate(data:${JSON.stringify(data).replace(/"(\w+)"\s*:/g, '$1:')}){
                status,
                message,
                redirect,
                internal_payment_code
            }
        }`;
        return await GraphQL(query, "mutate")
    }

    insertPaymentToServer = async (dataInfaq: IFace.DataInfaq): Promise<void> => {
        let result: any;
        result = await this.paymentQuery(dataInfaq);
        let { status, data }: { status: boolean, data: IFace.Donate } = result;
        if (result && status) {
            let tmpData: IFace.IsDonate = data.isDonate
            this.isSetResponseCreatePayment(tmpData)
        }
        this.emit(PAYMENT_CREATE_DATA, { status })
    }

    isSetResponseCreatePayment = (data: IFace.IsDonate): void => {
        this.createPaymentResponse = data
    }

    isGetResponseCreatePayment = (): IFace.IsDonate | null => {
        return this.createPaymentResponse
    }


    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case PAYMENT_METHOD_GET_DATA:
                this.paymentMethods = null;
                this.getPaymentFromServer()
                break
            case PAYMENT_CREATE_DATA:
                this.insertPaymentToServer(action.payload)
                break;
            case PAYMENT_GET_DATA_DETAIL:
                this.getPaymentDetailFromServer(action.payload)
                break;
        }
    }
}

const paymentStore = new Payment();
dispatcher.register(paymentStore.handleActions.bind(paymentStore))
export default paymentStore;