import { EventEmitter } from 'events'
import dispatcher from '../utils/flux/index'
import { GraphQL } from '../services/index';
import {
    MY_DONATE_GET_DATA,
    TOTAL_DONATE_GET_DATA
} from '../actions/index'
import * as IFace from './donate.interface.store'

class Donate extends EventEmitter implements IFace.RootObject {
    donates: IFace.Donates | null
    totalDonate: number | null
    constructor() {
        super();
        this.donates = null;
        this.totalDonate = null;
    }

    getMyDonateQuery = async ({ currentPage, begin, end }: { currentPage?: number, begin: number, end: number }): Promise<any> => {
        let query: string
        query = `{
                    donates(
                            limit:10, 
                            currentPage:${currentPage ?? 1}, 
                            filters:{
                                begin:${begin},
                                end:${end},
                                me:true,
                            }
                        ){
                        limit,
                        total,
                        currentPage,
                        successDonate,
                        models{
                            created_by,
                            created_at,
                            amount_origin,
                            status,
                            validated_at,
                            validated_by
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getMyDonateFromServer = async (payload: any): Promise<void> => {
        let result: any;
        result = await this.getMyDonateQuery(payload);
        let { status, data }: { status: boolean, data: IFace.Donate } = result;
        if (result && status) {
            let tmpData: IFace.Donates = data.donates
            if (this.donates) {
                tmpData.models = [...this.donates.models, ...tmpData.models]
            }
            this.isSetMyDonate(tmpData)
        }
        this.emit(MY_DONATE_GET_DATA, { status })
    }

    isSetMyDonate = (data: IFace.Donates): void => {
        this.donates = data
    }

    isGetMyDonate = (): IFace.Donates | null => {
        return this.donates
    }

    //total donate
    getTotalDonateQuery = async (): Promise<any> => {
        let query: string
        query = `{totalDonate}`;
        return await GraphQL(query)
    }

    getTotalDonateFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getTotalDonateQuery();
        let { data }: { data: IFace.TotalDonateData } = result;
        if (result && data) {
            this.isSetTotalDonate(data.totalDonate)
        }
        this.emit(TOTAL_DONATE_GET_DATA, { status: true })
    }

    isSetTotalDonate = (data: number | null): void => {
        this.totalDonate = data
    }

    isGetTotalDonate = (): number | null => {
        return this.totalDonate
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case MY_DONATE_GET_DATA:
                this.getMyDonateFromServer(action.payload)
                break
            case TOTAL_DONATE_GET_DATA:
                this.getTotalDonateFromServer()
                break
        }
    }
}

const institutionStore = new Donate();
dispatcher.register(institutionStore.handleActions.bind(institutionStore))
export default institutionStore;