import React, { FC } from 'react'
import { TouchableOpacity, View, Clipboard } from 'react-native'

interface IPopUp {
    title: string,
    showPopUp?: boolean,
    children: React.ReactElement,
    onClose: () => void
}

export default (props: IPopUp): React.ReactElement => {
    let { title, showPopUp, children, onClose } = props
    return (
        <React.Fragment>
            <div style={{
                display: showPopUp ? "block" : "none",
                backdropFilter: 'saturate(200%) blur(5px)',
                backgroundColor: 'rgba(0,0,0,0.6)',
                zIndex: 999,
                position: 'fixed',
                width: '100%',
                top: 0,
                left: 0,
                height: '100vh',
                overflowY: 'scroll',
            }}>
                <div className="menu rounded-m"
                    style={{
                        marginTop: 100,
                        left: '50%',
                        width: 400,
                        marginLeft: '-200px',
                        zIndex: 1000,
                        marginBottom: 500,
                        backgroundColor: 'rgb(255 255 255 / 97%)'
                    }}
                >
                    <div className="menu-title">
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ flex: 1 }}>
                                <h1 className="istitle">{title}</h1>
                            </View>
                            <View style={{ width: 50, paddingTop: 28 }}>
                                <TouchableOpacity onPress={() => { onClose() }}>
                                    <a><i className="fa fa-times-circle"></i></a>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </div>
                    <div className="divider divider-margins mt-0 mb-0"></div>
                    <div className="content mt-0">
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}