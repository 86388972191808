interface Loc {
    latitude: number,
    longitude: number
}

const earthRadius: number = 6371

const greatCircleDistance = (angle: number) => 2 * Math.PI * earthRadius * (angle / 360)
const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180)
const radiansToDegrees = (radians: number) => radians * (180 / Math.PI)

const centralSubtendedAngle = (locationX: Loc, locationY: Loc): number => {
    const locationXLatRadians = degreesToRadians(locationX.latitude)
    const locationYLatRadians = degreesToRadians(locationY.latitude)

    let result: number = radiansToDegrees(
        Math.acos(
            Math.sin(locationXLatRadians) * Math.sin(locationYLatRadians) +
            Math.cos(locationXLatRadians) *
            Math.cos(locationYLatRadians) *
            Math.cos(
                degreesToRadians(
                    Math.abs(locationX.longitude - locationY.longitude)
                )
            )
        )
    )
    return result
}

export default (locationX: Loc, locationY: Loc) => Math.round((greatCircleDistance(centralSubtendedAngle(locationX, locationY))) * 100) / 100
