import { EventEmitter } from 'events'
import dispatcher from './../utils/flux/index'
import { GraphQL } from './../services/index';
import {
    AUTH_LOGIN,
    AUTH_REGISTER,
    AUTH_LOGIN_GOOGLE,
    AUTH_FORGOT_PASSWORD,
    AUTH_RENEW_TOKEN,
    AUTH_ACTIVATION,
} from '../actions/index'
import * as IFace from './auth.interface.store'

class Auth extends EventEmitter implements IFace.RootObject {
    login: IFace.ModelLogin | null
    forgotPassword: any
    register: any
    renewToken: string | null
    activation: any
    constructor() {
        super();
        this.login = null
        this.forgotPassword = null
        this.register = null
        this.renewToken = null
        this.activation = null
    }

    loginQuery = async (username: string | null, password: string | null): Promise<any> => {
        let query: string
        query = `{
                    login(username:"${username}", password:"${password}"){
                        status,
                        message,
                        token
                    }
                }`;
        return await GraphQL(query)
    }

    loginGoogleQuery = async (tokenId: string): Promise<any> => {
        let query: string
        query = `mutation{ 
            loginGoogle(token_id:${JSON.stringify(tokenId)}){
                status,
                message,
                token
            }
        }`;
        return await GraphQL(query, "mutate")
    }

    registerQuery = async (data: IFace.ParamsRegister): Promise<any> => {
        let query: string
        query = `mutation{ 
            register(data:${JSON.stringify(data).replace(/"(\w+)"\s*:/g, '$1:')}){
                status,
                message
            }
        }`;
        return await GraphQL(query, "mutate")
    }

    forgotPasswordQuery = async (username: string): Promise<any> => {
        let query: string
        query = `{
                    forgotPassword(username:"${username}"){
                        status,
                        message
                    }
                }`;
        return await GraphQL(query)
    }

    renewTokenQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    renewToken
                }`;
        return await GraphQL(query)
    }

    activationQuery = async (token: string): Promise<any> => {
        let query: string
        query = `{
                    activation(token:"${token}"){
                        status,
                        message
                    }
                }`;
        return await GraphQL(query)
    }

    renewTokenToServer = async (): Promise<void> => {
        let result: any;
        result = await this.renewTokenQuery();
        let { status, data }: { status: boolean, data: IFace.RenewToken } = result;
        if (result && status) {
            this.setRenewToken(data.renewToken)
        }
        this.emit(AUTH_RENEW_TOKEN, { status })
    }

    loginToServer = async (username: string, password: string): Promise<void> => {
        let result: any;
        result = await this.loginQuery(username, password);
        let { status, data }: { status: boolean, data: IFace.Login } = result;
        if (result && status) {
            let tmpData: IFace.ModelLogin = data.login
            this.setDataUser(tmpData)
        }
        this.emit(AUTH_LOGIN, { status })
    }

    loginToServerByGoogle = async (tokenId: string): Promise<void> => {
        let result: any;
        result = await this.loginGoogleQuery(tokenId);
        let { status, data }: { status: boolean, data: IFace.LoginGoogle } = result;
        if (result && status) {
            let tmpData: IFace.ModelLogin = data.loginGoogle
            this.setDataUser(tmpData)
        }
        this.emit(AUTH_LOGIN_GOOGLE, { status })
    }

    registerToServer = async (isData: IFace.ParamsRegister): Promise<void> => {
        let result: any;
        result = await this.registerQuery(isData);
        let { status, data }: { status: boolean, data: IFace.Register } = result;
        if (result && status) {
            let tmpData: IFace.ModelRegister = data.register
            this.setDataRegister(tmpData)
        }
        this.emit(AUTH_REGISTER, { status })
    }

    sendReqForgotPassword = async (username: string): Promise<void> => {
        let result: any;
        result = await this.forgotPasswordQuery(username);
        let { status, data }: { status: boolean, data: IFace.ForgotPassword } = result;
        if (result && status) {
            let tmpData: IFace.ModelForgotPassword = data.forgotPassword
            this.setDataForgotPassword(tmpData)
        }
        this.emit(AUTH_FORGOT_PASSWORD, { status })
    }

    sendActivation = async (token: string): Promise<void> => {
        let result: any;
        result = await this.activationQuery(token);
        let { status, data }: { status: boolean, data: IFace.DataActivation } = result;
        if (result && status) {
            let tmpData: IFace.ModelActivation = data.activation
            this.setDataActivation(tmpData)
        }
        this.emit(AUTH_ACTIVATION, { status })
    }

    setDataActivation = (data: IFace.ModelActivation): void => {
        this.activation = data
    }

    isDataActivation = (): IFace.ModelActivation | null => {
        return this.activation
    }

    setRenewToken = (data: string | null): void => {
        this.renewToken = data
    }

    isRenewToken = (): string | null => {
        return this.renewToken
    }

    setDataUser = (data: IFace.ModelLogin): void => {
        this.login = data
    }

    isDataUser = (): IFace.ModelLogin | null => {
        return this.login
    }

    setDataForgotPassword = (data: IFace.ModelForgotPassword): void => {
        this.forgotPassword = data
    }

    isDataForgotPassword = (): IFace.ModelForgotPassword | null => {
        return this.forgotPassword
    }

    setDataRegister = (data: IFace.ModelRegister): void => {
        this.register = data
    }

    isDataRegister = (): IFace.ModelRegister | null => {
        return this.register
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case AUTH_LOGIN:
                var { username, password }: IFace.ParamsLogin = action.payload
                this.loginToServer(username, password)
                break
            case AUTH_LOGIN_GOOGLE:
                this.loginToServerByGoogle(action.payload)
                break
            case AUTH_FORGOT_PASSWORD:
                this.sendReqForgotPassword(action.payload)
                break
            case AUTH_REGISTER:
                this.registerToServer(action.payload)
                break
            case AUTH_RENEW_TOKEN:
                this.renewTokenToServer()
                break
            case AUTH_ACTIVATION:
                this.sendActivation(action.payload)
                break

        }
    }
}

const authStore = new Auth();
dispatcher.register(authStore.handleActions.bind(authStore))
export default authStore;