import React, { ReactElement } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import programStore from '../../stores/program.store'
import sliderStore from '../../stores/slider.store'
import {
    getDataProgram,
    getDataSlider,
    getDataPinnedProgram,
    SLIDER_GET_DATA,
    SLIDER_GET_DATA_DETAIL,
    PINNED_PROGRAM_GET_DATA,
    PROGRAM_GET_DATA
} from '../../actions/index'
import { SliderLoader, List2Loader, CarouselLoader, ContentTumbnailLoader } from '../../components/loaders/index'
import { FlatList, TouchableOpacity, View } from 'react-native'
import * as IFaceProgram from '../../stores/program.interface.store'
import * as IFaceSlider from '../../stores/slider.interface.store'
import NoData from '../../components/nodatas/index'
import prettyNumber from '../../utils/pretty-number/index'
import truncateText from '../../utils/truncate/index'
import LoadMore from '../../components/loadmores/index'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux'
import parse from 'html-react-parser'
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import Loaded from '../../components/loaded/index'


const SliderElement = (props: any): ReactElement => {
    let { data }: { data: IFaceSlider.Sliders } = props
    if (data) {
        if (data.models.length) {
            return (
                <div className="card card-style card-responsive-parent card-margin">
                    <Carousel showIndicators={false} showThumbs={false}>
                        {
                            data.models.map((val, data) => {
                                return (
                                    <div className="card card-responsive    ">
                                        <img src={val.image} className="img-fluid card-responsive rounded-cstm" />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
            )
        }
        return (<NoData />)
    }
    return (<SliderLoader />)
}

const PinnedProgramElement = (props: any): ReactElement => {
    let { data }: { data: IFaceProgram.PinnedPrograms } = props
    if (data) {
        if (data.models.length) {
            return (
                <OwlCarousel
                    className="owl-theme"
                    loop
                    nav
                    autoWidth
                >
                    {
                        data.models.map((item, index) => {
                            return (
                                <div className="card card-style card-responsive-parent is-carousel card-auto card-margin" style={{ marginBottom: 18 }}>
                                    <div className="card card-responsive card-carousel rounded-cstm">
                                        <img src={item.thumbnail} className="card-responsive card-carousel rounded-cstm" />
                                    </div>
                                    <div className="content mt-3">
                                        <p className="font-600 mb-0 color-highlight">Program</p>
                                        <h3 className="font-700">{truncateText(item.title, 35)}</h3>
                                        <p className="mb-0">{parse(truncateText((item.desc) ?? "", 89))}</p>
                                        <div className="divider mb-2"></div>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                            <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                            <TouchableOpacity>
                                                <Link to={`/program/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                            </TouchableOpacity>
                                        </View>
                                    </div>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            )
        }
        return (<NoData />)
    }
    return (<CarouselLoader />)
}

const ProgramElement = (props: any): ReactElement => {
    let { data }: { data: IFaceProgram.Programs } = props
    if (data) {
        if (data.models.length) {
            return (
                <FlatList
                    data={data.models}
                    onScrollEndDrag={() => console.log("end")}
                    renderItem={({ item }: { item: IFaceProgram.ModelPrograms }) => {
                        return (
                            <div className="card card-style card-responsive-parent card-auto card-margin">
                                <div className="card card-responsive rounded-cstm">
                                    <img src={item.thumbnail} className="card-responsive rounded-cstm" />
                                </div>
                                <div className="content mt-3">
                                    <p className="font-600 mb-0 color-highlight">Program</p>
                                    <h3 className="font-700">{truncateText(item.title, 40)}</h3>
                                    <p className="mb-1">{truncateText((item.desc) ?? "", 89)}</p>
                                    <div className="divider mb-2"></div>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 0, justifyContent: 'space-between' }}>
                                        <span className="color-highlight-2 font-22">{prettyNumber(item.donate, false, 1000000, 0, "0")} IDR</span>
                                        <TouchableOpacity>
                                            <Link to={`/program/${item.url_seo}`}><button className="btn gradient-blue font-13 btn-s font-600 rounded-sm">SELENGKAPNYA</button></Link>
                                        </TouchableOpacity>
                                    </View>
                                </div>
                            </div>
                        )
                    }}
                    keyExtractor={(item: any) => item.id}
                />
            )
        }
        return (<NoData />)
    }
    return (<ContentTumbnailLoader />)
}

const DataEnd = (props: any): ReactElement => (
    <LoadMore data={props.data}>
        <ContentTumbnailLoader />
    </LoadMore>
)

export const Program = (): ReactElement => {

    let isFinished: boolean = false
    let isTotalData: number
    let isGotData: number
    let currentPage: number

    const [sliders, setSliders] = React.useState<IFaceSlider.Sliders | null | undefined>()
    const [topPrograms, setTopPrograms] = React.useState<IFaceProgram.PinnedPrograms | null | undefined>()
    const [program, setPrograms] = React.useState<IFaceProgram.Programs | null | undefined>()
    const [dataEnd, setDataEnd] = React.useState<string>("")

    const _onGetDataProgram = (): void => {
        let result = programStore.isGetProgram()
        isTotalData = result?.total ?? 0
        isGotData = result?.models.length ?? 0
        currentPage = result?.currentPage ?? 1
        setPrograms(result)
        setDataEnd("default")
        isFinished = true
    }

    const _onGetDataPinnedProgram = (): void => {
        let result = programStore.isGetPinnedProgram()
        setTopPrograms(result)
    }

    const _onGetDataSlider = (): void => {
        let result = sliderStore.isGetSlider()
        setSliders(result)
    }

    React.useEffect(() => {
        programStore._addListener(PROGRAM_GET_DATA, _onGetDataProgram)
        programStore._addListener(PINNED_PROGRAM_GET_DATA, _onGetDataPinnedProgram)
        sliderStore._addListener(SLIDER_GET_DATA, _onGetDataSlider)

        window.addEventListener("scroll", _onScroll)

        let program = programStore.isGetProgram()
        if (!program) {
            getDataProgram()
        }
        else {
            _onGetDataProgram()
        }

        let pinnedProgram = programStore.isGetPinnedProgram()
        if (!pinnedProgram) {
            getDataPinnedProgram()
        }
        else {
            _onGetDataPinnedProgram()
        }

        getDataSlider("program")

        return () => {
            programStore._removeListener(PROGRAM_GET_DATA, _onGetDataProgram)
            programStore._removeListener(PINNED_PROGRAM_GET_DATA, _onGetDataPinnedProgram)
            sliderStore._removeListener(SLIDER_GET_DATA_DETAIL, _onGetDataSlider)
            window.removeEventListener("scroll", _onScroll)
        }
    }, [])


    const __loadMore = async (): Promise<void> => {
        isFinished = false
        getDataProgram(currentPage + 1)
    }

    const _onScroll = (): void => {
        if ((isFinished) && ((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
            if (isGotData < isTotalData) {
                setDataEnd("loadmore")
                __loadMore()
            }
            else {
                setDataEnd("end")
            }
        }
    }

    return (
        <React.Fragment>
            <Seo
                title={`LIST PROGRAM DONASI TERPERCAYA - ${process.env.REACT_APP_NAME}`}
                desc='List program donasi terpercaya berikut ini merupakan program yang dimiliki oleh platform ringanin.id dari yayasan Swadaya Ummah'
                recent_link={window.location.href}
            />
            <SliderElement data={sliders} />
            <h2 className="color-highlight">Program unggulan</h2>
            <PinnedProgramElement data={topPrograms} />
            <h2 className="color-highlight">Semua program</h2>
            <ProgramElement data={program} />
            <DataEnd data={dataEnd} />
            {/* jangan di hapus */}
            <Loaded data={program} />
        </React.Fragment>
    )
}