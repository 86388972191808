import React, { ReactElement } from 'react'
import { Helmet } from "react-helmet";

type ISeo = {
    is_article?: boolean | false
    title: string | null,
    image_uri?: string | null,
    desc?: string,
    recent_link: string,
    type?: string,
    date?: string | null
}

const Seo: React.FC<ISeo> = (props) => {
    let { title, desc, recent_link, type, date, is_article, image_uri, } = props;

    let meta = [
        {
            name: 'description',
            content: (desc ?? process.env.REACT_APP_DEF_DESC),
        },
        {
            name: 'robots',
            content: (`index, follow, max-image-preview:${image_uri && is_article ? "large" : -1}, max-snippet:-1, max-video-preview:-1`),
        },
        {
            property: 'og:locale',
            content: "id_ID",
        },
        {
            property: 'og:type',
            content: (type ?? process.env.REACT_APP_DEF_TYPE),
        },
        {
            property: 'og:description',
            content: (desc ?? process.env.REACT_APP_DEF_DESC),
        },
        {
            property: 'og:title',
            content: (title ?? process.env.REACT_APP_DEF_TITLE),
        },
        {
            property: 'og:url',
            content: (recent_link ?? process.env.PUBLIC_URL),
        },
        {
            property: 'og:site_name',
            content: (process.env.REACT_APP_NAME),
        },
    ];

    if (date) {
        meta = [...meta, {
            name: 'article:published_time',
            content: (date),
        }]
    }

    if (image_uri && is_article) {
        meta = [...meta, {
            property: 'og:image',
            content: image_uri,
        },
        {
            property: 'og:image:width',
            content: (process.env.REACT_APP_DEF_IMG_W),
        },
        {
            property: 'og:image:height',
            content: (process.env.REACT_APP_DEF_IMG_H),
        },]
    }

    if (is_article) {
        meta = [...meta,
        {
            name: 'twitter:card',
            content: 'summary_large_image',
        },
        {
            name: 'twitter:label1',
            content: process.env.REACT_APP_WRITER,
        },
        {
            name: 'twitter:label2',
            content: process.env.REACT_APP_ESTIMATE_STR,
        },
        {
            name: 'twitter:data1',
            content: process.env.REACT_APP_WRITER_BY,
        },
        {
            name: 'twitter:data2',
            content: process.env.REACT_APP_ESTIMATE,
        },
        ];

    }

    return (
        <Helmet htmlAttributes={{ lang: 'en' }}
            title={title ?? process.env.REACT_APP_DEF_TITLE}
            meta={meta}
        />
    )
}

export default Seo;