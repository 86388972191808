import dispatcher from '../utils/flux/index'
import { MY_DONATE_GET_DATA, TOTAL_DONATE_GET_DATA } from './index'

export const myDonateGetData = ({ currentPage, begin, end }: { currentPage?: number, begin: number, end: number }): void => {
    dispatcher.dispatch({
        type: MY_DONATE_GET_DATA,
        payload: {
            currentPage,
            begin,
            end
        }
    })
}

export const getTotalDonate = (): void => {
    dispatcher.dispatch({
        type: TOTAL_DONATE_GET_DATA
    })
}