import { EventEmitter } from 'events'
import dispatcher from '../utils/flux/index'
import { GraphQL } from '../services/index';
import {
    USER_GET_DATA,
    USER_UPDATE_DATA
} from '../actions/index'
import * as IFace from './user.interface.store'

class User extends EventEmitter implements IFace.RootObject {
    biodata: IFace.ModelBiodata | null
    updateBiodata: IFace.ResponseUpdateBiodata | null
    constructor() {
        super();
        this.biodata = null;
        this.updateBiodata = null;
    }

    getBiodataQuery = async (): Promise<any> => {
        let query: string
        query = `{
                    biodata{
                        full_name,
                        email,
                        phone,
                        image,
                        donate,
                        address,
                        role,
                        status
                    }
                }`;
        return await GraphQL(query)
    }

    getBiodataFromServer = async (): Promise<void> => {
        let result: any;
        result = await this.getBiodataQuery();
        let { status, data }: { status: boolean, data: IFace.Biodata } = result;
        if (result && status) {
            let tmpData: IFace.ModelBiodata = data.biodata
            this.isSetBiodata(tmpData)
        }
        this.emit(USER_GET_DATA, { status })
    }

    isSetBiodata = (data: IFace.ModelBiodata): void => {
        this.biodata = data
    }

    isGetBiodata = (): IFace.ModelBiodata | null => {
        return this.biodata
    }

    updateBiodataQuery = async (data: IFace.DataBiodata): Promise<any> => {
        let query: string
        query = `mutation{ 
            updateBiodata(data:${JSON.stringify(data).replace(/"(\w+)"\s*:/g, '$1:')}){
                status,
                message
            }
        }`;
        return await GraphQL(query, "mutate")
    }

    updateBiodataToServer = async (dataBiodata: IFace.DataBiodata): Promise<void> => {
        let result: any;
        result = await this.updateBiodataQuery(dataBiodata);
        let { status, data }: { status: boolean, data: IFace.UpdateBiodata } = result;
        if (result && status) {
            let tmpData: IFace.ResponseUpdateBiodata = data.updateBiodata
            this.isSetUpdateBiodata(tmpData)
        }
        this.emit(USER_UPDATE_DATA, { status })
    }

    isSetUpdateBiodata = (data: IFace.ResponseUpdateBiodata): void => {
        this.updateBiodata = data
    }

    isGetUpdateBiodata = (): IFace.ResponseUpdateBiodata | null => {
        return this.updateBiodata
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case USER_GET_DATA:
                this.getBiodataFromServer()
                break
            case USER_UPDATE_DATA:
                let { payload }: { payload: IFace.DataBiodata } = action
                this.updateBiodataToServer(payload)
        }
    }
}

const user = new User();
dispatcher.register(user.handleActions.bind(user))
export default user;