import React, { ReactChild } from 'react';
import ContentLoader from "react-content-loader"

export const ContentTumbnailLoader = (props: any) => (
    <div className="card card-style card-responsive-parent card-auto card-margin">
        <ContentLoader
            speed={2.5}
            style={{ width: '100%' }}
            viewBox="0 0 480 282"
            backgroundColor="#fff"
            foregroundColor="#E5E5E5"
            {...props}
        >
            <rect x="0" y="0" rx="16" ry="16" width="100%" height="100%" />
        </ContentLoader>
        <div className="content mt-3">
            <ContentLoader
                speed={2.5}
                style={{ width: '100%', height: 'auto' }}
                backgroundColor="#fff"
                foregroundColor="#E5E5E5"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="100" height="15" />
                <rect x="0" y="25" rx="4" ry="4" width="70%" height="25" />

                <rect x="0" y="60" rx="0" ry="0" width="100" height="15" />
                <rect x="110" y="60" rx="0" ry="0" width="175" height="15" />
                <rect x="300" y="60" rx="0" ry="0" width="100%" height="15" />

                <rect x="0" y="85" rx="0" ry="0" width="130" height="15" />
                <rect x="150" y="85" rx="0" ry="0" width="70" height="15" />

                <rect x="0" y="115" rx="0" ry="0" width="100%" height="1" />

                <rect x="0" y="125" rx="4" ry="4" width="80" height="35" />
                <rect x="120" y="125" rx="4" ry="4" width="100%" height="35" />
            </ContentLoader>
        </div>
    </div>
)