import React, { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { Header, Footer, FooterReferral } from './../components/layouts/index'
import { checkAuth } from './../utils/auth'
import { Redirect } from 'react-router-dom'
import { setLocalStorage } from '../utils/local-storage'
import { connect } from 'react-redux'
import * as REDUXTYPES from './../reduxs/type-redux/index'
import * as IFaceAuth from './../stores/auth.interface.store'

type ILayoutRouterRender = {
    setAuth: (payload: IFaceAuth.ILogin | null) => void,
    setLatLong: (payload: IFaceAuth.ILatLong | null) => void,
    setFooterDetection: (payload: boolean) => void,
    authRedirect?: string,
    isAuthRedirect?: string,
    header?: boolean,
    footer?: boolean,
    path: any,
    exact?: any,
    children: any,
}

const LayoutRouterRender: React.FC<ILayoutRouterRender> = (props): ReactElement => {
    const { setAuth, setLatLong, setFooterDetection } = props
    let { authRedirect, isAuthRedirect, header, footer, children, path } = props
    const [loading, setLoading] = React.useState<boolean>(true)
    const [isLogin, setIsLogin] = React.useState<any>(null)

    const _checkLogin = async (): Promise<null | IFaceAuth.ILogin> => {
        return await checkAuth("auth");
    }

    const _getPreciseLocation = async (): Promise<any> => {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (position) {
                resolve([position.coords.latitude, position.coords.longitude]);
            });
        });
    }

    const _getCurrentLocation = async () => {
        try {
            const position = await _getPreciseLocation()
            let payload: IFaceAuth.ILatLong
            payload = {
                latitude: position[0],
                longitude: position[1]
            }
            setLatLong(payload)
        }
        catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        _getCurrentLocation()
        if (loading) {
            (async function () {
                let result = await _checkLogin()
                if (result) {
                    setIsLogin(result)
                    setAuth(result)
                }
                setLoading(false)
            })()
        }
        if (footer) { setFooterDetection(true) }
        else { setFooterDetection(false) }
    })

    if (loading && (authRedirect || isAuthRedirect)) {
        return <React.Fragment>...</React.Fragment>
    }

    if (!isLogin && authRedirect) {
        setLocalStorage("redirect", path)
        return <Redirect to={authRedirect} />
    }

    if (isLogin && isAuthRedirect) {
        return <Redirect to={isAuthRedirect} />
    }

    return (
        <div>
            {header && <Header />}
            <div className="page-content" style={{ maxWidth: 480, margin: 'auto', marginTop: -20 }}>
                <Route {...props}>
                    {children}
                </Route>
            </div>
            {footer && <Footer />}
        </div>
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {}
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setAuth: (payload: IFaceAuth.ILogin | null) => dispatch({ type: REDUXTYPES.AUTH_LOGIN, payload }),
        setLatLong: (payload: IFaceAuth.ILatLong | null) => dispatch({ type: REDUXTYPES.LAT_LANG, payload }),
        setFooterDetection: (payload: boolean) => dispatch({ type: REDUXTYPES.FOOTER_DETECTION, payload }),
    }
}
export const LayoutRouter = connect(mapStateToProps, mapDispatchToProps)(LayoutRouterRender);

export default LayoutRouter