import { EventEmitter } from 'events'
import dispatcher from './../utils/flux/index'
import { GraphQL } from './../services/index';
import {
    SLIDER_GET_DATA,
} from '../actions/index'
import * as IFace from './slider.interface.store'

class Slider extends EventEmitter implements IFace.RootObject {
    sliders: IFace.Sliders | null
    sliderDetail: IFace.ModelSliders | null
    constructor() {
        super();
        this.sliders = null;
        this.sliderDetail = null
    }

    getSliderQuery = async (type: string): Promise<any> => {
        let query: string
        query = `{
                    sliders(limit:5, filters:{position:"${type}", sort:"id DESC"}){
                        limit,
                        total,
                        currentPage,
                        models{
                            url_seo,
                            title,
                            image
                        }
                    }
                }`;
        return await GraphQL(query)
    }

    getSliderFromServer = async (type: string): Promise<void> => {
        let result: any;
        result = await this.getSliderQuery(type);
        let { status, data }: { status: boolean, data: IFace.Slider } = result;
        if (result && status) {
            let tmpData: IFace.Sliders = data.sliders
            if (this.sliders) {
                tmpData.models = [...this.sliders.models, ...tmpData.models]
            }
            this.isSetSlider(tmpData)
        }
        this.emit(SLIDER_GET_DATA, { status })
    }

    isSetSlider = (data: IFace.Sliders | null): void => {
        this.sliders = data
    }

    isGetSlider = (): IFace.Sliders | null => {
        return this.sliders
    }

    _addListener(type: string, callback: (data: boolean) => void): void {
        this.on(type, ({ status }: { status: boolean }) => {
            callback(status)
        });
    }

    _removeListener(type: string, callback: (data: boolean) => void): void {
        this.removeListener(type, callback)
    }

    //handle action
    handleActions = async (action: any): Promise<void> => {
        switch (action.type) {
            case SLIDER_GET_DATA:
                this.isSetSlider(null)
                this.getSliderFromServer(action.payload.type)
                break
        }
    }
}

const sliderStore = new Slider();
dispatcher.register(sliderStore.handleActions.bind(sliderStore))
export default sliderStore;