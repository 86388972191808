import React, { ReactElement } from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom'

import {
    NotFound,
    Home,
    WorshipPlace,
    Program,
    ProgramDetail,
    Institution,
    InstitutionDetail,
    Login,
    Register,
    ForgotPassword,
    PaymentDetail,
    PaymentStatus,
    WorshipPlaceDetail,
    Profile,
    UpdateProfile,
    DonateReport,
    Activation,
    ArticleDetail,
    MyReferral
} from '../views/index'

import LayoutRouter from './layout.router'

const MainRouter = (): ReactElement => {
    return (
        <Switch>
            <LayoutRouter exact header footer path="/">
                <React.Fragment>
                    <Home />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header isAuthRedirect="/account" footer path="/login">
                <React.Fragment>
                    <Login />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header isAuthRedirect="/account" path="/register">
                <React.Fragment>
                    <Register />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header isAuthRedirect="/account" path="/forgot-password">
                <React.Fragment>
                    <ForgotPassword />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header footer path="/worship-place">
                <React.Fragment>
                    <WorshipPlace />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header footer path="/program">
                <React.Fragment>
                    <Program />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header footer path="/institution">
                <React.Fragment>
                    <Institution />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header authRedirect="/login" footer path="/account">
                <React.Fragment>
                    <Profile />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header authRedirect="/login" path="/update-profile">
                <React.Fragment>
                    <UpdateProfile />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header authRedirect="/login" path="/donate-report">
                <React.Fragment>
                    <DonateReport />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header authRedirect="/login" path="/my-referral">
                <React.Fragment>
                    <MyReferral />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/term-and-condition">
                <React.Fragment>
                    <MyReferral />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/worship-place/:url_seo">
                <React.Fragment>
                    <WorshipPlaceDetail />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/program/:url_seo">
                <React.Fragment>
                    <ProgramDetail />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/institution/:url_seo">
                <React.Fragment>
                    <InstitutionDetail />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/payment/detail/:code">
                <React.Fragment>
                    <PaymentDetail />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/payment/status/:code">
                <React.Fragment>
                    <PaymentStatus />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/activation/:token">
                <React.Fragment>
                    <Activation />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter exact header path="/article/:url_seo">
                <React.Fragment>
                    <ArticleDetail />
                </React.Fragment>
            </LayoutRouter>
            <LayoutRouter header footer path="*">
                <React.Fragment>
                    <NotFound />
                </React.Fragment>
            </LayoutRouter>
        </Switch>
    );
}

export default MainRouter;
