import React, { ReactChild } from 'react';
import { View } from 'react-native'
import ContentLoader from "react-content-loader"

export const ContentDetailLoader = (props: any) => {
    let { children }: { children: React.ReactElement } = props
    return (< React.Fragment >
        <div className="page-content pb-3" style={{ marginTop: -64 }}>
            <div className="card card-style rounded-m pb-1">
                <div className="drag-line"></div>
                <div className="content">
                    <h5 className="font-600 mb-n1 color-highlight">
                        {
                            <ContentLoader
                                speed={2.5}
                                backgroundColor="#fff"
                                foregroundColor="#E5E5E5"
                                viewBox="0 0 480 230"
                                style={{ width: '100%' }}
                                {...props}
                            >
                                <rect x="0" y="5" rx="4" ry="4" width="30%" height="15" />
                                <rect x="0" y="35" rx="4" ry="4" width="70%" height="35" />
                                <rect x="0" y="85" rx="4" ry="4" width="50%" height="15" />
                                <rect x="55%" y="85" rx="4" ry="4" width="30%" height="15" />
                                <rect x="0" y="110" rx="4" ry="4" width="20%" height="15" />
                                <rect x="25%" y="110" rx="4" ry="4" width="70%" height="15" />

                                <rect x="0" y="145" rx="4" ry="4" width="30%" height="40" />
                                <rect x="85%" y="145" rx="4" ry="4" width="15%" height="40" />
                            </ContentLoader>
                        }
                    </h5>
                </div>
            </div>
        </div>
        {
            children && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )
        }
    </React.Fragment >)
}