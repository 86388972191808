import dispatcher from '../utils/flux/index'
import { MY_REFERRAL_GET_DATA, MY_REFERRAL_CREATE_DATA } from './index'

export const myReferralGetData = ({ currentPage, begin, end }: { currentPage?: number, begin: number, end: number }): void => {
    dispatcher.dispatch({
        type: MY_REFERRAL_GET_DATA,
        payload: {
            currentPage,
            begin,
            end
        }
    })
}

export const myReferralCreateData = ({ url, contentType }: { url: string, contentType: string | undefined }): void => {
    dispatcher.dispatch({
        type: MY_REFERRAL_CREATE_DATA,
        payload: {
            url: url,
            contentType: contentType
        }
    })
}