import React, { ReactElement } from 'react'
import QRCode from 'qrcode.react'
import { getDataPaymentDetail, PAYMENT_GET_DATA_DETAIL } from './../../actions/index'
import paymentStore from './../../stores/payment.store'
import { notify } from 'react-notify-toast';
import prettyNumber from './../../utils/pretty-number/index'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import { useParams } from "react-router"
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import Loaded from '../../components/loaded/index'

export const PaymentStatus = (): ReactElement => {
    const { code }: { code: string } = useParams()
    const [isCode, setIsCode] = React.useState<string>(code)
    const [status, setStatus] = React.useState<number>()
    const [msg, setMsg] = React.useState<string>()
    const [amount, setAmount] = React.useState<number>()
    const [paymentCode, setPaymentCode] = React.useState<string>()

    const _onPaymentDetailGetData = (status: boolean) => {
        let result = paymentStore.isGetPaymentDetail()
        if (status && result) {
            setIsCode(result.internal_payment_code)
            setStatus(result.status)
            setMsg(result.msg)
            setAmount(result.amount_origin)
            setPaymentCode(result.payment_info_str)
        }
        else {
            notify.show("Sistem sibuk", "error", 3000)
        }
    }

    React.useEffect(() => {
        getDataPaymentDetail(isCode)
        paymentStore._addListener(PAYMENT_GET_DATA_DETAIL, _onPaymentDetailGetData)
        return () => {
            paymentStore._removeListener(PAYMENT_GET_DATA_DETAIL, _onPaymentDetailGetData)
        }
    }, [])
    return (
        <React.Fragment>
            <Seo
                title={`STATUS PEMBAYARAN - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <div className="card card-style">
                <div className="text-center pt-4 pb-4">
                    <h1>ID: #{isCode ?? "RIN..."}</h1>
                    {
                        status == 0 && paymentCode == "COPP" && (
                            <p className="boxed-text-xl mb-0">
                                Cek notif pada aplikasi OVO Kamu ya...
                            </p>
                        )
                    }
                    <p className="boxed-text-xl mb-2">
                        Status pembayaran kamu untuk <strong>{msg ?? "..."}</strong> sebesar {prettyNumber(amount, false, 0, 0, "...")} IDR <strong>{status == 0 ? "PENDING" : (status == -1 ? "GAGAL" : status == 1 ? "SUKSES" : "Loading...")}</strong>
                    </p>
                    <div style={{ fontSize: 100, color: status == 1 ? 'green' : status == -1 ? 'red' : 'orange' }}>
                        <i className={status == 1 ? 'far fa-check-circle' : status == -1 ? 'far fa-times-circle' : 'fa fa-exclamation-circle'}></i>
                    </div>
                    <p className="text-center font-10 pt-2 mb-0">Tindakan ini otomatis dilakukan oleh sistem</p>
                </div>
            </div>

            <div className="card card-style">
                <div className="content mb-0">
                    <h2>Catatan:</h2>
                    <p className="mb-2">Baca catatan dibawah ini untuk mengetahui status pembayaran</p>
                    <ul>
                        <li>Jika status pembayaran anda masih "Pending", berarti pembayaran Anda belum masuk/ belum tervalidasi oleh sistem kami</li>
                        <li>Jika status pembayaran anda "Gagal", berarti tagihan yang Anda miliki telah melewati batas maksimal pembayaran</li>
                        <li>Untuk mengupdate status pembayaran, silahkan reload halaman ini</li>
                        <li>Kembali ke halaman utama, klik tombol dibawah</li>
                    </ul>
                    <Link to={`/`} className="generate-qr-button btn btn-l btn-full shadow-l rounded-s bg-highlight font-600 mt-4">Beranda</Link>
                </div>
                <div className="divider divider-margins mb-2 mt-4"></div>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
        </React.Fragment>
    )
}
