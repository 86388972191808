import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import NoData from './../../components/nodatas/index'
import { SliderLoader } from './../../components/loaders/index'
import * as IFaceSlider from './../../stores/slider.interface.store'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import prettyNumber from './../../utils/pretty-number/index'
import 'react-lazy-load-image-component/src/effects/blur.css';
import LoadMore from './../../components/loadmores/index'
import { ContentTumbnailLoader } from '../../components/loaders/index'

type ISliderElement = {
    data: IFaceSlider.Sliders | null | undefined
}

export const SliderElement: React.FC<ISliderElement> = ({ data }): React.ReactElement => {
    if (!data) {
        return <SliderLoader />
    }
    if (!data.models.length) {
        return <NoData />
    }
    return (
        <React.Fragment>
            <div className={"card card-style card-responsive-parent card-margin"}>
                <Carousel showIndicators={false} showThumbs={false}>
                    {
                        data.models.map((val) => {
                            return (
                                <React.Fragment>
                                    <div className="card card-responsive rounded-cstm" style={{ backgroundColor: '#E5E5E5' }}>
                                        <LazyLoadImage
                                            key={val.id}
                                            src={val.image}
                                            alt={val.title}
                                            effect="blur"
                                            className="img-fluid card-responsive rounded-cstm"
                                        />
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </Carousel>
            </div>
        </React.Fragment>
    )
}

type ILastDonate = {
    totalDonate: number | null;
}

export const LastDonate: React.FC<ILastDonate> = ({ totalDonate }): React.ReactElement => {
    const random_item = (items: any): number => {
        return items[Math.floor(Math.random() * items.length)];
    }
    return (
        <div className="card card-style card-responsive-parent card-auto card-margin">
            <div className="content">
                <p className="font-600 mb-0 color-highlight">Donasi terakhir</p>
                <div className="divider mt-2 mb-2"></div>
                <table className="table table-sm table-borderless text-center rounded-sm" style={{ overflow: 'hidden' }}>
                    <thead>
                        <tr className="bg-blue-dark">
                            <th scope="col" className="color-white">Nama</th>
                            <th scope="col" className="color-white">Nominal</th>
                            <th scope="col" className="color-white">Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Hamba Allah</th>
                            <td>{random_item([5, 6, 8.5, 100, 20, 25, 70, 90, 22, 2.5])}K IDR</td>
                            <td>{random_item([10, 16, 19, 20])} menit yang lalu</td>
                        </tr>
                        <tr>
                            <th>Hamba Allah</th>
                            <td>{random_item([5, 6, 8.5, 100, 20, 25, 70, 90, 22, 2.5])}K IDR</td>
                            <td>{random_item([27, 34, 40, 51])} menit yang lalu</td>
                        </tr>
                        <tr>
                            <th>Hamba Allah</th>
                            <td>{random_item([5, 6, 8.5, 100, 20, 25, 70, 90, 22, 2.5, 14])}K IDR</td>
                            <td>{random_item([1, 1.3, 2.3, 3])} jam yang lalu</td>
                        </tr>
                        <tr>
                            <th>Hamba Allah</th>
                            <td>{random_item([5, 6, 8.5, 100, 20, 25, 70, 90, 22, 2.5, 13])}K IDR</td>
                            <td>{random_item([3.5, 4, 4.1, 6.1])} jam yang lalu</td>
                        </tr>
                        <tr>
                            <th>Hamba Allah</th>
                            <td>{random_item([5, 6, 8.5, 100, 20, 25, 70, 90, 22, 2.5, 12])}K IDR</td>
                            <td>{random_item([1, 2, 3])} hari yang lalu</td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-center">
                    <div className="divider mt-2 mb-2"></div>
                    <span className="font-20 color-highlight-2">{prettyNumber(totalDonate, false, 1000000, 0, "0")} IDR</span>
                    <div className="divider mt-1 mb-0"></div>
                    <span>Total donasi pada semua kampanye</span>
                </div>
            </div>

        </div>
    )
}

type IDataEnd = {
    data: any
}

export const DataEnd: React.FC<IDataEnd> = (props): React.ReactElement => (
    <LoadMore data={props.data}>
        <ContentTumbnailLoader />
    </LoadMore>
)
