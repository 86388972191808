import { createStore, combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { Auth, LatLong, FooterDetection } from './../reducers-redux/index'

const MainReducer = combineReducers({
    loadingBar: loadingBarReducer,
    auth: Auth,
    latLong: LatLong,
    footerDetection: FooterDetection
});

export const mainStore = createStore(MainReducer)