import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { TouchableOpacity, View } from 'react-native'
import PopUp from '../../components/popups/index'
import { removeLocalStorage } from './../../utils/local-storage/index'
import prettyNumber from '../../utils/pretty-number/index'
import * as IFaceAuth from './../../stores/auth.interface.store'
import * as REDUXTYPES from './../../reduxs/type-redux/index'
import { Redirect, Link } from 'react-router-dom'
import { getDataUser, USER_GET_DATA } from './../../actions/index'
import userStore from './../../stores/user.store'
import truncateText from './../../utils/truncate/index'
import Seo from '../../components/seo/index'
import htmlToString from '../../utils/html-to-string/index'
import Loaded from '../../components/loaded/index'

export const ProfileRender = (props: any): ReactElement => {
    const { auth, setAuth }: { auth: IFaceAuth.DataLogin, setAuth: (payload: null) => void } = props

    const [popUpLogout, setPopUpLogout] = React.useState<boolean>(false)
    const [redirect, setRedirect] = React.useState<string>()
    const [image, setImage] = React.useState<string>()
    const [fullName, setFullName] = React.useState<string>()
    const [username, setUsername] = React.useState<string>()
    const [totalDonate, setTotalDonate] = React.useState<number>()

    const _isLogout = (): void => {
        removeLocalStorage("auth")
        setPopUpLogout(false)
        setAuth(null)
        // setRedirect("/login")
        window.location.href = "/login"
    }

    const _action = (type: string, data?: any): void => {
        switch (type) {
            case "logout":
                setPopUpLogout(true)
                break
            case "confirm-logout":
                _isLogout()
                break
            case "cancel-logout":
                setPopUpLogout(false)
                break
        }
    }

    const _onGetBiodata = () => {
        let result = userStore.isGetBiodata()
        if (result) {
            setImage(result.image)
            setFullName(result.full_name)
            setUsername(result.email)
        }
        setTotalDonate(result?.donate)
    }

    React.useEffect(() => {
        userStore._addListener(USER_GET_DATA, _onGetBiodata)
        getDataUser()
        return userStore._removeListener(USER_GET_DATA, _onGetBiodata)
    }, [])

    return (
        <React.Fragment>
            <Seo
                title={`PROFIL SAYA - ${process.env.REACT_APP_NAME}`}
                recent_link={window.location.href}
            />
            <PopUp title="Konfirmasi" showPopUp={popUpLogout} onClose={() => { _action("cancel-logout") }}>
                <React.Fragment>
                    <p className="mb-0">Yakin logout?</p>
                    <p className="mb-0">tenang, kamu bisa login kapanpun kamu mau kok</p>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 24 }}>
                        <View style={{ width: 100, marginRight: 16 }}>
                            <button onClick={() => { _action("cancel-logout") }} className="btn btn-full gradient-blue font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                                Kembali
                            </button>
                        </View>
                        <View style={{ flex: 1 }}>
                            <button onClick={() => { _action("confirm-logout") }} className="btn btn-full gradient-orange font-13 btn-l font-600 mt-3 rounded-sm" style={{ width: '100%' }}>
                                Keluar
                            </button>
                        </View>
                    </View>
                </React.Fragment>
            </PopUp>
            <div className="card card-style rounded-m pb-1">
                <div className="content">
                    <h2 className="color-highlight">Profil Kamu</h2>
                    <div className="divider mt-2 mb-2"></div>
                    <div className="d-flex">
                        <img src={image ?? ((auth.login && auth.login.image) ?? "/theme/images/pictures/17s.jpg")} className="logo-institution rounded-s" />
                        <div className="w-100 ml-3 pt-1">
                            <h3 className="font-500 mt-n1">{fullName ? truncateText((fullName) ?? "", 30) : (auth.login && auth.login.full_name)}</h3>
                            <p className="mb-1 mt-n1">{username ? truncateText((username) ?? "", 30) : (auth.login && auth.login.email)}</p>
                            <span className="color-highlight-2 font-20 mt-n2">{prettyNumber(totalDonate, false, 1000000, 0, "0")} IDR</span>
                            <p className="font-12 mt-n1">total donasi kamu</p>
                        </div>
                    </div>
                    <div className="divider mb-1 mt-2"></div>
                    <div className="list-group list-custom-large">
                        <TouchableOpacity>
                            <Link to="/update-profile">
                                <i className="fas fa-user font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                                <span>Data diri</span>
                                <strong>{"Ubah data diri?"}</strong>
                                <i className="fa fa-angle-right"></i>
                            </Link>
                        </TouchableOpacity>
                    </div>
                    <div className="list-group list-custom-large">
                        <Link to="/my-referral">
                            <i className="fas fa-user font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                            <span>Referral saya</span>
                            <strong>{"Referral saya"}</strong>
                            <i className="fa fa-angle-right"></i>
                        </Link>
                    </div>
                    <div className="list-group list-custom-large">
                        <TouchableOpacity>
                            <Link to="/donate-report">
                                <i className="fas fa-user font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                                <span>Donasi saya</span>
                                <strong>{"Lihat riwayat donasi Kamu"}</strong>
                                <i className="fa fa-angle-right"></i>
                            </Link>
                        </TouchableOpacity>
                    </div>
                </div>
            </div>

            <div className="card card-style rounded-m pb-1">
                <div className="content">
                    <div className="list-group list-custom-large">
                        <a className="visited-link">
                            <i className="fas fa-phone font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                            <span>Bantuan & FAQ</span>
                            <strong>{"Klik disini untuk FAQ"}</strong>
                            <i className="fa fa-angle-right"></i>
                        </a>
                    </div>
                    <div className="list-group list-custom-large">
                        <a className="visited-link">
                            <i className="fas fa-phone font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                            <span>Ketentuan Layanan</span>
                            <strong>{"Klik disini untuk Ketentuan Layanan Kami"}</strong>
                            <i className="fa fa-angle-right"></i>
                        </a>
                    </div>
                    <div className="list-group list-custom-large mb-4">
                        <a className="visited-link">
                            <i className="fas fa-phone font-14 bg-green-dark color-white rounded-sm shadow-xl"></i>
                            <span>Versi Aplikasi</span>
                            <strong>{"v.1.05"}</strong>
                            <i className="fa fa-angle-right"></i>
                        </a>
                    </div>
                    <TouchableOpacity onPress={() => { _action("logout") }}>
                        <button className="btn gradient-orange font-13 font-600 rounded-sm">Log out</button>
                    </TouchableOpacity>
                </div>
                <p style={{ textAlign: 'center', fontSize: 12 }}>&copy; 2020 by Yayasan Swadaya Ummah</p>
            </div>
            {/* jangan di hapus */}
            <Loaded data={null} />
            {
                redirect && <Redirect to={redirect} />
            }
        </React.Fragment>
    )
}

// conect to redux
const mapStateToProps = (state: any) => {
    return {
        isLatLong: state.latLong,
        auth: state.auth
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setAuth: (payload: null) => dispatch({ type: REDUXTYPES.AUTH_LOGIN, payload }),
    }
}
export const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileRender);

export default Profile
