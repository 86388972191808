import dispatcher from '../utils/flux/index'
import { PROGRAM_GET_DATA, PROGRAM_GET_DATA_DETAIL, PINNED_PROGRAM_GET_DATA, PROGRAM_GET_DATA_DEFAULT } from './index'

export const getDataProgram = (currentPage?: number): void => {
    dispatcher.dispatch({
        type: PROGRAM_GET_DATA,
        payload: currentPage
    })
}

export const getDataPinnedProgram = (): void => {
    dispatcher.dispatch({
        type: PINNED_PROGRAM_GET_DATA
    })
}

export const getDataProgramDetail = (url?: string): void => {
    dispatcher.dispatch({
        type: PROGRAM_GET_DATA_DETAIL,
        payload: url
    })
}

export const getDataProgramDefault = (): void => {
    dispatcher.dispatch({
        type: PROGRAM_GET_DATA_DEFAULT,
    })
}

