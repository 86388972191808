export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_LOGIN_GOOGLE = "AUTH_LOGIN_GOOGLE"
export const AUTH_REGISTER = "AUTH_REGISTER"
export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD"
export const AUTH_RENEW_TOKEN = "AUTH_RENEW_TOKEN"
export const AUTH_ACTIVATION = "AUTH_ACTIVATION"




