import { client } from "./client.graphql"
import { gql } from "apollo-boost"
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { mainStore as store } from './../../reduxs/index';
import { removeLocalStorage } from './../../utils/local-storage/index'
import { notify } from 'react-notify-toast';

export const GraphQL = async (isQuery: string, variables: any = null) => {
    store.dispatch(showLoading())
    try {
        let result;
        if (!variables) {
            result = await client
                .query({
                    query: gql`${isQuery}`
                });
        }
        else {
            result = await client
                .mutate({
                    // variables: variables,
                    mutation: gql`${isQuery}`
                });
        }
        store.dispatch(hideLoading())
        return {
            status: true,
            message: 'ok',
            ...result
        }
    }
    catch (e) {
        console.log("error", e)
        store.dispatch(hideLoading())
        if (e.graphQLErrors[0] && e.graphQLErrors[0].extensions && e.graphQLErrors[0].extensions == "credential") {
            removeLocalStorage("auth")
            notify.show("Sessi habis", "error")
            window.location.reload()
        }
        return {
            status: false,
            message: 'error',
            data: { error: e.message },
            loading: false,
            networkStatus: 0,
            stale: false
        }
    }
} 
