import React, { ReactChild } from 'react';
import ContentLoader from "react-content-loader"
import { FlatList, TouchableOpacity, View } from 'react-native'

export const List2Loader = (props: any) => (
    <div className="card card-style card-responsive-parent card-auto card-margin">
        <div className="content">
            <ContentLoader
                speed={3}
                viewBox="0 0 480 130"
                style={{ width: '100%' }}
                backgroundColor="#fff"
                foregroundColor="#E5E5E5"
                {...props}
            >
                <rect x="140" y="0" rx="4" ry="4" width="60%" height="25" />

                <rect x="140" y="40" rx="4" ry="4" width="100" height="15" />
                <rect x="260" y="40" rx="4" ry="4" width="160" height="15" />

                <rect x="140" y="65" rx="4" ry="4" width="230" height="15" />
                <rect x="390" y="65" rx="4" ry="4" width="90" height="15" />

                <rect x="140" y="95" rx="4" ry="4" width="90" height="30" />
                <rect x="350" y="95" rx="4" ry="4" width="140" height="30" />

                <rect x="0" y="0" rx="8" ry="8" width="120" height="100%" />
            </ContentLoader>
        </div>
    </div>
)
