import React from 'react';
import ContentLoader from "react-content-loader"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

export const CarouselLoader = (props: any) => (
    <OwlCarousel
        className="owl-theme"
        loop
        nav
        autoWidth
    >
        <div className="card card-style card-responsive-parent is-carousel card-auto card-margin" style={{ marginBottom: 18 }}>
            <ContentLoader
                speed={2.5}
                style={{ width: '100%' }}
                viewBox="0 0 480 282"
                backgroundColor="#fff"
                foregroundColor="#E5E5E5"
                {...props}
            >
                <rect x="0" y="0" rx="16" ry="16" width="100%" height="100%" />
            </ContentLoader>
            <div className="content mt-3">
                <ContentLoader
                    speed={2.5}
                    style={{ width: '100%', height: 'auto' }}
                    backgroundColor="#fff"
                    foregroundColor="#E5E5E5"
                    {...props}
                >
                    <rect x="0" y="0" rx="0" ry="0" width="100" height="15" />
                    <rect x="0" y="25" rx="4" ry="4" width="70%" height="25" />

                    <rect x="0" y="60" rx="0" ry="0" width="100" height="15" />
                    <rect x="110" y="60" rx="0" ry="0" width="175" height="15" />

                    <rect x="0" y="85" rx="0" ry="0" width="130" height="15" />
                    <rect x="150" y="85" rx="0" ry="0" width="70" height="15" />

                    <rect x="0" y="115" rx="0" ry="0" width="100%" height="1" />

                    <rect x="0" y="125" rx="4" ry="4" width="80" height="35" />
                    <rect x="120" y="125" rx="4" ry="4" width="100%" height="35" />
                </ContentLoader>
            </div>
        </div>
    </OwlCarousel>
)